import React from 'react';
//import './PSStoreTermComponent.scss';
import styles from './PSStoreTermComponent.module.scss'

const PSStoreTermComponent = () => {
  return (
    <div className={styles.content_980px}>
      <h1>利用規約</h1>
      <div className={styles.term_body}>
        <p>本利用規約（以下「本規約」といいます。）には、Piascore 株式会社（以下「当社」といいます。）の提供する電子楽譜サービスのご利用にあたり、登録ユーザーの皆様に遵守していただかなければならない事項及び当社と登録ユーザーの皆様との間の権利義務関係が定められております。当該サービスを登録ユーザーとしてご利用になる方は、本規約に同意する前に、必ず全文お読み下さいますようお願い致します。</p>
      </div>
      <div className={styles.term_headline}>第1条　適　用</div>
      <div className={styles.term_body}>
        <p>1.　本規約は、本サービス（第2条に定義）の利用に関する当社と登録ユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、登録ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。</p>
        <p>2.　当社が当社ウェブサイト（第2条に定義）上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。</p>
      </div>
      <div className={styles.term_headline}>第2条 定　義</div>
      <div className={styles.term_body}>
        <p>本規約において使用する以下の用語は各々以下に定める意味を有するものとします。</p>
        <div className={styles.term_body_sub}>
          <p>(1)　「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含みます。）を意味します。</p>
          <p>(2)　「当社ウェブサイト」とは、そのドメインが「piascore.com」である当社が運営するウェブサイト（理由の如何を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</p>
          <p>(3)　「登録希望者」とは、第3条において定義された「登録希望者」を意味します。</p>
          <p>(4)　「登録情報」とは、第3条において定義された「登録情報」を意味します。</p>
          <p>(5)　「登録ユーザー」とは、第3条に基づき本サービスの利用者としての登録がなされた個人又は法人を意味します。</p>
          <p>(6)　「本サービス」とは、当社が提供する Piascore（ピアスコア）という名称の電子楽譜サービス（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</p>
          <p>(7)　「利用契約」とは、第3条第3項に定義される「利用契約」を意味します。</p>
        </div>
      </div>
      <div className={styles.term_headline}>第3条 登　録</div>
      <div className={styles.term_body}>
        <p>1.　本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録情報」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。</p>
        <p>2.　当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、登録を拒否することがあります。</p>
        <div className={styles.term_body_sub}>
          <p>(1)　当社に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合</p>
          <p>(2)　未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合</p>
          <p>(3)　反社会的勢力（暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋、その他暴力、威力又は詐欺的手法を使用して経済的利益を追求する集団又は個人を意味します。以下同じ。）である、又は資金提供その他を通じて反社会的勢力の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力との何らかの交流若しくは関与を行っていると当社が判断した場合</p>
          <p>(4)　その他、当社が登録を適当でないと判断した場合</p>
        </div>
        <p>3.　当社は、前項その他当社の基準に従って、登録希望者の登録の可否を判断し、当社が登録を認める場合にはその旨を登録希望者に通知します。かかる通知により登録希望者の登録ユーザーとしての登録は完了し、本規約の諸規定に従った本サービスの利用にかかる契約（以下「利用契約」といいます。）が登録ユーザーと当社の間に成立します。</p>
        <p>4.　登録ユーザーは、登録情報に変更があった場合は、遅滞なく、当社の定める方法により、当該変更事項を当社に通知し、当社から要求された資料を提出するものとします。</p>
      </div>
      {/* 第4条 本サービスの利用 */}
      <div className={styles.term_headline}>第4条 本サービスの利用</div>
      <div className={styles.term_body}>
        <p>登録ユーザーは、利用契約の有効期間中、本規約に従って、当社の定める方法に従い、本サービスを利用することができます。</p>
      </div>

      {/* 第5条 料金及び支払方法 */}
      <div className={styles.term_headline}>第5条 料金及び支払方法</div>
      <div className={styles.term_body}>
        <p>1.　登録ユーザーは、本サービス利用の対価として、下記に定める利用料金を負担するものとします。</p>
        <p>2.　利用料金は、サービスご利用後、利用料金を翌月末日までに当社の指定する方法で当社に支払うものとします。振込手数料その他支払に必要な費用は登録ユーザーの負担とします。</p>
        <p>3.　登録ユーザーが利用料金の支払を遅滞した場合、登録ユーザーは年14.6％の割合による遅延損害金を当社に支払うものとします。</p>
      </div>
      <div className={styles.term_headline}>第6条 パスワード及びユーザーIDの管理</div>
      <div className={styles.term_body}>
        <p>1.　登録ユーザーは、自己の責任において、パスワード及びユーザーIDを管理及び保管するものとし、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしてはならないものとします。</p>
        <p>2.　パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等による損害の責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。</p>
        <p>3.　登録ユーザーは、パスワード又はユーザーIDが盗まれたり、第三者に使用されていることが判明した場合には、直ちにその旨を当社に通知するとともに、当社からの指示に従うものとします。</p>
      </div>
      
      <div className={styles.term_headline}>第7条 禁止行為</div>
      <div className={styles.term_body}>
        <p>1.　登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為をしてはなりません。</p>
        <div className={styles.term_body_sub}>
          <p>(1)　当社、又は本サービスの他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含みます。）</p>
          <p>(2)　犯罪行為に関連する行為又は公序良俗に反する行為</p>
          <p>(3)　異性交際に関する情報を送信する行為</p>
          <p>(4)　コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為</p>
          <p>(5)　当社による本サービスの運営を妨害するおそれのある行為</p>
          <p>(6)　その他、当社が不適切と判断する行為</p>
        </div>
        <p>2.　当社は、本サービスにおける登録ユーザーによる情報の送信行為が前項各号のいずれかに該当し、又は該当するおそれがあると当社が判断した場合には、登録ユーザーに事前に通知することなく、当該情報の全部又は一部を削除することができるものとします。当社は、本項に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。</p>
      </div>

      <div className={styles.term_headline}>第8条 本サービスの停止等</div>
      <div className={styles.term_body}>
        <p>1.　当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。</p>
        <div className={styles.term_body_sub}>
          <p>(1)　本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合</p>
          <p>(2)　コンピューター、通信回線等が事故により停止した場合</p>
          <p>(3)　火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</p>
          <p>(4)　その他、当社が停止又は中断を必要と判断した場合</p>
        </div>
        <p>2.　当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は登録ユーザーに事前に通知するものとします。</p>
        <p>3.　当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。</p>
      </div>
      <div className={styles.term_headline}>第9条 設備の負担等</div>
      <div className={styles.term_body}>
        <p>1.　本サービスの提供を受けるために必要な、コンピューター、ソフトウェアその他の機器、通信回線その他の通信環境等の準備及び維持は、登録ユーザーの費用と責任において行うものとします。</p>
        <p>2.　登録ユーザーは自己の本サービスの利用環境に応じて、コンピューター・ウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を自らの費用と責任において講じるものとします。</p>
        <p>3.　登録ユーザーは、本サービスの利用開始に際し又は本サービスの利用中に、当社ウェブサイトからのダウンロードその他の方法によりソフトウェア等を登録ユーザーのコンピューター等にインストールする場合には、登録ユーザーが保有する情報の消滅若しくは改変又は機器の故障、損傷等が生じないよう十分な注意を払うものとし、当社は登録ユーザーに発生したかかる損害について一切責任を負わないものとします。</p>
      </div>

      <div className={styles.term_headline}>第10条 権利帰属</div>
      <div className={styles.term_body}>
        <p>1.　当社ウェブサイト及び本サービスに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に定める登録に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</p>
        <p>2.　当社ウェブサイト又は本サービスにおいて、登録ユーザーが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、無償で自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。</p>
      </div>
      <div className={styles.term_headline}>第11条 登録取消等</div>
      <div className={styles.term_body}>
        <p>1.　 当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を取り消すことができます。</p>
        <div className={styles.term_body_sub}>
          <p>(1)　本規約のいずれかの条項に違反した場合</p>
          <p>(2)　登録情報に虚偽の事実があることが判明した場合</p>
          <p>(3)　支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合</p>
          <p>(4)　12ヶ月以上本サービスの利用がなく、当社からの連絡に対して応答がない場合</p>
          <p>(5)　第3条第2項各号に該当する場合</p>
          <p>(6)　その他、当社が登録ユーザーとしての登録の継続を適当でないと判断した場合</p>
        </div>
        <p>2.　前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。</p>
        <p>3.　当社及び登録ユーザーは、それぞれ7日前までに当社所定の方法で相手方に通知することにより、登録ユーザーの登録を取り消すことができます。</p>
        <p>4.　当社は、本条に基づき当社が行った行為により登録ユーザーに生じた損害について一切の責任を負いません。</p>
      </div>
      <div className={styles.term_headline}>第12条 保証の否認及び免責</div>
      <div className={styles.term_body}>
        <p>1.　当社は、本サービスの利用によるお客様の技能向上その他の具体的な成果につき如何なる保証も行うものではありません。本サービスは現状有姿で提供されるものであり、当社は本サービスについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、一切保証を致しません。</p>
        <p>2.　登録ユーザーが当社から直接又は間接に、本サービス、当社ウェブサイト、本サービスの他の登録ユーザーその他の事項に関する何らかの情報を得た場合であっても、当社は登録ユーザーに対し本規約において規定されている内容を超えて如何なる保証も行うものではありません。</p>
        <p>3.　登録ユーザーは、本サービスを利用することが、登録ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、登録ユーザーによる本サービスの利用が、登録ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。</p>
        <p>4.　本サービス又は当社ウェブサイトに関連して登録ユーザーと他の登録ユーザー又は第三者との間において生じた取引、連絡、紛争等については、登録ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。</p>
        <p>5.　当社は、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、登録ユーザーのメッセージ又は情報の削除又は消失､登録ユーザーの登録の取消、本サービスの利用によるデータの消失又は機器の故障若しくは損傷、その他本サービスに関連して登録ユーザーが被った損害につき、賠償する責任を一切負わないものとします。</p>
        <p>6.　当社ウェブサイトから他のウェブサイトへのリンク又は他のウェブサイトから当社ウェブサイトへのリンクが提供されている場合でも、当社は、当社ウェブサイト以外のウェブサイト及びそこから得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。</p>
        <p>7.　当社は、本サービスに関連して登録ユーザーが被った損害について、一切賠償の責任を負いません。消費者契約法の適用その他の理由により当社が登録ユーザーに対して損害賠償責任を負う場合においても、当社の賠償責任は、損害の事由が生じた時点から遡って過去30日間に登録ユーザーから現実に受領した本サービスの利用料金の総額を上限とします。</p>
      </div>

      <div className={styles.term_headline}>第13条 ユーザーの賠償等の責任</div>
      <div className={styles.term_body}>
        <p>登録ユーザーは、本規約に違反することにより、又は本サービスの利用に関連して当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。</p>
      </div>
      <div className={styles.term_headline}>第14条 秘密保持</div>
      <div className={styles.term_body}>
        <p>1.　本規約において「秘密情報」とは、利用契約又は本サービスに関連して、登録ユーザーが、当社より書面、口頭若しくは記録媒体等により提供若しくは開示されたか、又は知り得た、当社の技術、営業、業務、財務、組織、その他の事項に関する全ての情報を意味します。但し、(1)当社から提供若しくは開示がなされたとき又は知得したときに、既に一般に公知となっていた、又は既に知得していたもの、(2)当社から提供若しくは開示又は知得した後、自己の責めに帰せざる事由により刊行物その他により公知となったもの、(3)提供又は開示の権限のある第三者から秘密保持義務を負わされることなく適法に取得したもの、(4)秘密情報によることなく単独で開発したもの、(5)当社から秘密保持の必要なき旨書面で確認されたものについては、秘密情報から除外するものとします。</p>
        <p>2.　登録ユーザーは、秘密情報を本サービスの利用の目的のみに利用するとともに、当社の書面による承諾なしに第三者に当社の秘密情報を提供、開示又は漏洩しないものとします。</p>
        <p>3.　第2項の定めに拘わらず、登録ユーザーは、法律、裁判所又は政府機関の命令、要求又は要請に基づき、秘密情報を開示することができます。但し、当該命令、要求又は要請があった場合、速やかにその旨を当社に通知しなければなりません。</p>
        <p>4.　登録ユーザーは、当社から求められた場合にはいつでも、遅滞なく、当社の指示に従い、秘密情報並びに秘密情報を記載又は包含した書面その他の記録媒体物及びその全ての複製物を返却又は廃棄しなければなりません。</p>
      </div>
      <div className={styles.term_headline}>第15条 有効期間</div>
      <div className={styles.term_body}>
        <p>利用契約は、本サービスの提供期間中、登録ユーザーについて第3条に基づく登録が完了した日から当該登録ユーザーの登録が取り消された日まで、当社と登録ユーザーとの間で有効に存続するものとします。</p>
      </div>
      <div className={styles.term_headline}>第16条 本規約等の変更</div>
      <div className={styles.term_body}>
        <p>1.　当社は、本サービスの内容を自由に変更できるものとします。</p>
        <p>2.　当社は、本規約（当社ウェブサイトに掲載する本サービスに関するルール、諸規定等を含みます。以下本項において同じ。）を変更できるものとします。当社は、本規約を変更した場合には、登録ユーザーに当該変更内容を通知するものとし、当該変更内容の通知後、登録ユーザーが本サービスを利用した場合又は当社の定める期間内に登録取消の手続をとらなかった場合には、登録ユーザーは、本規約の変更に同意したものとみなします。</p>
      </div>
      <div className={styles.term_headline}>第17条 連絡/通知</div>
      <div className={styles.term_body}>
        <p>本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。</p>
      </div>
      <div className={styles.term_headline}>第18条 本規約の譲渡等</div>
      <div className={styles.term_body}>
        <p>1.　登録ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</p>
        <p>2.　当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</p>
      </div>
      <div className={styles.term_headline}>第19条 分離可能性</div>
      <div className={styles.term_body}>
        <p>本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により 無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及び登録ユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。</p>
      </div>
      <div className={styles.term_headline}>第20条 存続規定</div>
      <div className={styles.term_body}>
        <p>第5条（未払がある場合に限ります。）、第6条第2項、第7条第2項、第8条第3項、第9条、第10条、第11条第2項及び第4項、第12条から第14条まで、並びに第18条から第21条までの規定は利用契約の終了後も有効に存続するものとします。但し、第14条については、利用契約終了後1年間に限り存続するものとします。</p>
      </div>
      <div className={styles.term_headline}>第21条 準拠法及び管轄裁判所</div>
      <div className={styles.term_body}>
        <p>本規約の準拠法は日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
      </div>
      <div className={styles.term_headline}>第22条 協議解決</div>
      <div className={styles.term_body}>
        <p>当社及び登録ユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。</p>
      </div>
      <div className={styles.date_and_change}>
        &#8203;【2017年10月16日 制定】&#8203;<br />&#8203;【2018年2月21日 変更】&#8203;
      </div>
      <div className={styles.home_button}>
        <a href="/"><i className="fa fa-home"></i>ホームに戻る</a>
      </div>
    </div>
  );
}

export default PSStoreTermComponent;
