import React from 'react';
import './PSStoreAboutComponent.scss';

const PSStoreAboutComponent = () => {
  return (
    <>
      <div className="about_wrapper" style={{ margin: "0 auto" }}>
        <div className="bold-title">Piascore 楽譜ストアについて</div>
        <div style={{ margin: "40px 0 80px" }}>
          <p>
            <strong>Piascore 楽譜ストア</strong>
            は、デジタル楽譜を購入できるサービスです。
          </p>
        </div>
        <div className="about-section">
          <div className="about-title" data-title="楽譜を見たり、印刷できます" />
          <div className="about-contents">
            <div className="about-content-text">
              Piascore 楽譜ストアで購入した楽譜のファイルは、PDF（Portable Document
              Format）形式となっており、タブレットやスマートフォンで閲覧したり、ご自宅などのプリンタから紙として印刷することができます。
              <br />
              <br />
              デジタル楽譜ビューアーアプリ「Piascore」と連動しており、購入した楽譜を簡単に
              Piascore にもダウンロードすることができます。
            </div>
            <div className="about-content-images">
              <div style={{ margin: "auto auto auto 0" }}>
                <div style={{ width: 135, margin: "auto" }}>
                  <img
                    className="about_see_img"
                    src="/images/howto/on_tablet.png"
                    alt="On tablet"
                  />
                </div>
                <div style={{ marginTop: 33, textAlign: "center" }}>
                  タブレットで
                </div>
              </div>
              <div className="paper_imag_container">
                <div style={{ width: 135, margin: "auto" }}>
                  <img
                    className="about_see_img"
                    src="/images/howto/on_paper.png"
                    alt="On paper"
                  />
                </div>
                <div style={{ marginTop: 33, textAlign: "center" }}>紙で</div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section">
          <div className="about-title" data-title="購入はとっても簡単" />
          <div className="about-contents" style={{ display: "block" }}>
            <div className="about_search_text" style={{ textAlign: "center" }}>
              ほしい楽譜を探して、すぐ購入できます
            </div>
            <div className="aboutflow_container" style={{ margin: "46px 0" }}>
              <div
                style={{
                  background: "#FFF",
                  border: "1px solid #D7D7D7",
                  borderRadius: 9,
                  margin: "auto",
                  width: 212,
                  height: 294
                }}
              >
                <div
                  style={{
                    margin: 11,
                    width: 190,
                    height: 190,
                    display: "flex",
                    background: "linear-gradient(#FBFBFB, #E6E6E6)",
                    border: "1px solid #D7D7D7"
                  }}
                >
                  <img
                    className="howto_info1"
                    style={{ margin: "auto" }}
                    src="/assets/about/howto1-3aec604b82c2005b14e791fcff853892009207a001a2e607fe5409b889896d22.png"
                    alt="Howto1"
                  />
                </div>
                <div style={{ margin: "15px 11px 11px" }}>
                  ほしい楽譜を探しましょう。
                </div>
              </div>
              <div
                className="arrow_img"
                style={{ margin: "auto", height: 39, width: 30 }}
              >
                <img
                  src="/assets/about/howto_arrow-127e6cf449240347c9cf7c578e701b17bd02d608aecd344b378cd97a8127c73a.png"
                  alt="Howto arrow"
                />
              </div>
              <div
                style={{
                  background: "#FFF",
                  border: "1px solid #D7D7D7",
                  borderRadius: 9,
                  margin: "auto",
                  width: 212,
                  height: 294
                }}
              >
                <div
                  style={{
                    margin: 11,
                    width: 190,
                    height: 190,
                    display: "flex",
                    background: "linear-gradient(#FBFBFB, #E6E6E6)",
                    border: "1px solid #D7D7D7"
                  }}
                >
                  <img
                    className="howto_info2"
                    style={{ margin: "auto" }}
                    src="/assets/about/howto2-b686bc4b80dda5c9856e97ab03110c37d705c347322f7db29a55e3e6fd13c90d.png"
                    alt="Howto2"
                  />
                </div>
                <div style={{ margin: "15px 11px 11px" }}>
                  楽譜が見つかったら、クレジットカード決済で購入してください。
                </div>
              </div>
              <div
                className="arrow_img"
                style={{ margin: "auto", height: 39, width: 30 }}
              >
                <img
                  src="/assets/about/howto_arrow-127e6cf449240347c9cf7c578e701b17bd02d608aecd344b378cd97a8127c73a.png"
                  alt="Howto arrow"
                />
              </div>
              <div
                style={{
                  background: "#FFF",
                  border: "1px solid #D7D7D7",
                  borderRadius: 9,
                  margin: "auto",
                  width: 212,
                  height: 294
                }}
              >
                <div
                  style={{
                    margin: 11,
                    width: 190,
                    height: 190,
                    display: "flex",
                    background: "linear-gradient(#FBFBFB, #E6E6E6)",
                    border: "1px solid #D7D7D7"
                  }}
                >
                  <img
                    className="howto_info3"
                    style={{ margin: "auto" }}
                    src="/assets/about/howto3-df8a0b73ecaf23ec325ff0b466e9fb8f7bf195085ad385765065b2cc49aa7c27.png"
                    alt="Howto3"
                  />
                </div>
                <div style={{ margin: "15px 11px 11px" }}>
                  楽譜ファイル（PDF）がダウンロードされます。
                </div>
              </div>
            </div>
            <div className="more-info" style={{ margin: "60px 0 46px" }}>
              <div className="text">
                <a
                  style={{ display: "flex", textDecoration: "none" }}
                  href="/howto"
                >
                  <div>詳しくはこちら</div>
                  <i
                    style={{ margin: "0 auto auto 2px", fontSize: 15 }}
                    className="fa fa-arrow-right"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section">
          <div
            className="about-title"
            data-title="お支払いは安全なクレジットカード決済"
          />
          <div className="about-contents">
            <div className="about-content-text">
              VISA、MASTER CARD、JCB、AMERICAN EXPRESS、DISCOVER NETWORK、Diners
              Club に対応しております。
              <br />
              <br />
              <span style={{ fontSize: 11, lineHeight: "25px" }}>
                ※ 決済には、{" "}
                <span>
                  <a href="https://pay.jp/">PAY JP</a>
                </span>{" "}
                の決済システムを利用しています。
              </span>
            </div>
            <div className="about-content-images">
              <img
                className="cards_img"
                src="/assets/about/cards-52f6108d778d29f304c1f9e99433238018c9b1aa716cea958c580a8297eff684.png"
                alt="Cards"
              />
            </div>
          </div>
        </div>
        
        <div className="about-section">
          <div
            className="about-title"
            data-title="ほしい曲が見つからない場合はリクエスト"
          />
          <div className="about-contents">
            <div className="about-content-text">
              ほしい曲が見つからない・・・
              <br />
              <br />
              そんなときは、
              <span>
                <a href="/req">曲リクエスト</a>
              </span>
              よりリクエストしてみてください。
            </div>
            <div className="about-content-images">
              <img
                className="cards_img"
                src="/assets/about/req-6dc7d82ee04d57ee29c1f761745dc18381c61e51073a1e0717a037e6c59b2d77.png"
                alt="Req"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "20px 0 0 0" }}>
        <a href="/">
          <i className="fa fa-home" />
          ホームに戻る
        </a>
      </div>
    </>
  );
}

export default PSStoreAboutComponent;