import React from 'react';
import './PSRequestSheetMusicComponent.scss';

const PSRequestSheetMusicComponent = () => {
  
  
  
  return (
    <div className="req_wrapper" style={{ border: '1px solid #DDDDDD', margin: '0 auto', backgroundColor: '#FFFFFF', color: '#333333' }}>
        <div className="req_text_containner" style={{ height: '25%', borderBottom: '1px solid #F5F5F5' }}>
          <div style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold', lineHeight: '1.5em' }}>曲リクエスト</div>
          <div className="req_text">
            ご要望の楽譜がございましたら、リクエストをお願いいたします。<br />
            今後ご提供する楽譜の参考にさせていただきます。            
          </div>
          <div className="req_text" style={{color:"#FF3333"}}>
            リクエストは、全販売者様に公開されますので、氏名やメールアドレス、電話番号などの個人情報を記載しないでください。
          </div>
        </div>

        <div className="req_container" style={{ height: '75%', margin: '0 auto' }}>
          <form className="navbar-form" id="new_request" action="/send_request" acceptCharset="UTF-8" method="post">
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value="K4pV72+9a92ouz3GiHVk7GbIg0hpdrMOhR4zSgxm0VdAsKjE4T3udSZ6K241hb4onmH5BWGE6pOu6ulXvI1GmA==" />
            
            <div className="request-text-div" style={{ margin: '0 0 20px 0' }}>
              <div style={{ padding: '3px 0' }}><span style={{ fontWeight: 'bold' }}>曲名</span></div>
              <div><input className="form-control" style={{ height: '48px', fontSize: '16px', position: 'relative' }} required type="text" name="request[title]" id="request_title" /></div>
            </div>
            
            <div className="request-text-div" style={{ margin: '0 0 20px 0' }}>
              <div style={{ padding: '3px 0' }}><span style={{ fontWeight: 'bold' }}>楽器</span></div>
              <div><input className="form-control" style={{ height: '48px', fontSize: '16px', position: 'relative' }} required type="text" name="request[instrument]" id="request_instrument" /></div>
            </div>
            
            <div className="request-text-div" style={{ margin: '0 0 20px 0' }}>
              <div style={{ padding: '3px 0' }}><span style={{ fontWeight: 'bold' }}>アーティスト</span></div>
              <div><input className="form-control" style={{ height: '48px', fontSize: '16px', position: 'relative' }} type="text" name="request[artist]" id="request_artist" /></div>
            </div>
            
            <div className="request-text-div" style={{ margin: '0 0 20px 0' }}>
              <div style={{ padding: '3px 0' }}><span style={{ fontWeight: 'bold' }}>説明</span></div>
              <div><textarea className="form-control" style={{ height: '144px', fontSize: '16px', position: 'relative' }} name="request[description]" id="request_description"></textarea></div>
            </div>
            
            <div>
              <input type="submit" name="commit" value="送信する" className="piascore-blue-button" style={{ display: 'block', backgroundColor: '#0083EA' }} data-disable-with="送信中..." />
            </div>
            
            <div className="request-text-div" style={{ margin: '0 0 20px 0' }}>
              <div style={{fontSize:11, textAlign:"center"}}>送信すると<a target="_blank" href="https://piascore.com/ja/privacy/">プライバシーポリシー</a>に同意したことになります。</div>
            </div>
            
          </form>
        </div>
      </div>
  );
}

export default PSRequestSheetMusicComponent;
