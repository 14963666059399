import React from 'react';
//import './PSStoreLegalComponent.scss';
import styles from './PSStoreLegalComponent.module.scss'

const PSStoreLegalComponent = () => {
  return (
    <div className="content-980px" style={{ margin: "0 auto", padding: "40px" }}>
      <h1 style={{ fontSize: "20px", fontWeight: "bold" }}>特定商取引法に基づく表記</h1>
      <div>
        <div className={styles.term_headline}>販売事業主名</div>
        <div className={styles.term_body}>Piascore 株式会社</div>
        <div className={styles.term_headline}>運営責任者名</div>
        <div className={styles.term_body}>小池宏幸</div>
        <div className={styles.term_headline}>本社所在地</div>
        <div className={styles.term_body}>
          〒220-0011<br />
          神奈川県横浜市西区高島2-11-2<br />
          スカイメナー横浜 921号室
        </div>
        <div className={styles.term_headline}>問い合わせ窓口</div>
        <div className={styles.term_body}>
          <div className={styles.contact_item} style={{ marginTop: "10px" }}>
            <a href="/pre_contact" style={{ background: "#0083EA", color: "#FFFFFF", padding: "5px 10px", borderRadius: "15px" }}>
              お問合せフォームへ
              <span className="fas fa-arrow-right" style={{ marginLeft: "5px" }}></span>
            </a>
          </div>
          <div className={styles.contact_item}></div>
          <div className={styles.contact_item}>
            電話番号 ： 050-5534-1416<br />
            電話が繋がりにくい場合がございます。お急ぎの場合は、<a href="/pre_contact">お問い合わせフォーム</a>からご連絡をお願いいたします。<br />
            営業時間 ：平日10:00〜18:00
          </div>
        </div>
        <div className={styles.term_headline}>商品代金以外の必要料金</div>
        <div className={styles.term_body}>なし</div>
        <div className={styles.term_headline}>販売価格について</div>
        <div className={styles.term_body}>販売価格は、表示された金額（消費税込）といたします。</div>
        <div className={styles.term_headline}>代金（対価）の支払方法と時期</div>
        <div className={styles.term_body}>
          支払方法：クレジットカード(PAY.JP)<br />
          支払時期：楽譜データ購入時
        </div>
        <div className={styles.term_headline}>商品の引渡し時期</div>
        <div className={styles.term_body}>
          購入手続き完了後、即時ダウンロード可能<br />
          ダウンロードできなかった場合、<a href="/pre_contact" target="_blank">お問合せフォーム</a>よりその旨連絡の上、メール等にて提供
        </div>
        <div className={styles.term_headline}>返品・交換不良品について</div>
        <div className={styles.term_body}>商品特性上、返金・キャンセルはお受けできません。</div>
        <div style={{ textAlign: "right", margin: "40px 0 0 0", padding: "0" }}>
          {/* HTMLのエンティティはReact内ではそのまま表示されない可能性があるため、実際の日付や文字列に置き換える必要があります。 */}
          【2017年2月15日 制定】<br />
          【2020年10月12日 変更】<br />
          【2022年6月1日 変更】
        </div>
      </div>
      <div style={{ textAlign: "center", margin: "20px 0 0 0" }}>
        <a href="/"><i className="fa fa-home"></i>ホームに戻る</a>
      </div>
    </div>
  );
}

export default PSStoreLegalComponent;
