import React from 'react';
import './PSStorePublisherListComponent.scss';

const publishers = [
  { id: 1, name: '販売者 1' },
  { id: 2, name: '販売者 2' },
  { id: 3, name: '販売者 3' },
  { id: 4, name: '販売者 4' },
  { id: 5, name: '販売者 5' },
];

export default function PSStorePublisherListComponent() {
  return (
    <div className="publisher_box" style={{ overflow: 'hidden', margin: '0 auto' }}>
      {publishers.map(publisher => (
        <div key={publisher.id} className="publisher_btn_container">
          <a className="publisher_btn" href={`/search?c=${publisher.id}`}>
            <span className="publisher_text">{publisher.name}</span>
          </a>
        </div>
      ))}
      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
