import React from 'react';
import { useState, useRef } from 'react'
import axios from 'axios'
import _ from 'lodash'

import { Row, Col, Form, Button } from "react-bootstrap"
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Overlay from 'react-bootstrap/Overlay';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

//import styles from "./PSStorePaymentCredit.module.scss"
import styles from "./credit.module.scss"

export default function Credit({score_id, api_base_url, token_api_key, onSubmit, didGetToken, didPayment, didError, isDebug=false}) {

  console.log("score_id=" + score_id)
  // console.log("api_base_url=" + api_base_url)
  // console.log("token_api_key=" + token_api_key)

  const [cardNumber, setCardNumber]   = useState('')
  const [name, setName]               = useState('')
  const [expiaryDate, setExpiaryDate] = useState('')
  const [cvc, setCVC]                 = useState('')

  const [isOpenCVCPopover, setIsOpenCVCPopover] = useState(false)
  const [cvcPopoverTarget, setCvcPopoverTarget] = useState(null)

  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)

  const onChangeCardNumber = (e)  => setCardNumber(e.target.value)
  const onChangeName = (e)        => setName(e.target.value)
  const onChangeExpiryDate = (e)  => setExpiaryDate(e.target.value)
  const onChangeCVC = (e)         => setCVC(e.target.value)

  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する

  const ERROR_MESSAGES = {
    emptyCardNumber: 'カード番号が入力されていません',
    invalidCardNumber: 'カード番号が不正です',
    emptyExpiryDate: '有効期限が入力されていません',
    monthOutOfRange: '有効期限の月は01から12の間にしてください',
    yearOutOfRange: '有効期限の年を過去にすることはできません',
    dateOutOfRange: '有効期限を過去にすることはできません',
    invalidExpiryDate: '有効期限が不正です',
    emptyCVC: 'セキュリティコードが入力されていません',
    invalidCVC: 'セキュリティコードが不正です'
  }

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  })

  const { erroredInputs, touchedInputs } = meta;

  // 入力が正常になるまでボタンを無効化
  const isDisabled = meta.erroredInputs.cardNumber || meta.erroredInputs.expiryDate || meta.erroredInputs.cvc
  if (disabledSubmit != isDisabled) { // For avoiding too many renders
    setDisabledSubmit(isDisabled)
    setErrorMessage(null)
  }

  const apiConfig = {
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
  }

  const handleSubmit = async (event) => {

    // setErrorMessage(<>通信エラーが発生しました<br/><br/>続くようなら、お手数ですが<a target="_blank" href="/contact">コンタクトフォーム</a>よりご連絡ください</>)
    // return

    // dismiss payment form
    //onSubmit({})

    // 多重クリック防止
    // https://choippo.com/react-prevent-multiple-clicks/
    event.preventDefault();
    event.stopPropagation();
    if(submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）
    submitProcessing.current = true; // ③ 送信処理中フラグを立てる

    handlePayment()

    // show alert
    // {
    //   const options = {
    //     title: 'Title',
    //     message: 'Message',
    //     buttons: [
    //       {
    //         label: 'Yes',
    //         onClick: () => alert('Click Yes')
    //       },
    //       {
    //         label: 'No',
    //         onClick: () => alert('Click No')
    //       }
    //     ],
    //     closeOnEscape: true,
    //     closeOnClickOutside: true,
    //     keyCodeForClose: [8, 32],
    //     willUnmount: () => {},
    //     afterClose: () => {},
    //     onClickOutside: () => {},
    //     onKeypress: () => {},
    //     onKeypressEscape: () => {},
    //     overlayClassName: "overlay-custom-class-name"
    //   };
      
    //   confirmAlert(options);
    // }
  }

  const handlePayment = async () => {

    if (meta.erroredInputs.cardNumber || meta.erroredInputs.expiryDate || meta.erroredInputs.cvc) {
      console.log("Error")
      submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる

      return
    }
  
    try {
      // トークン取得
      let token = ''
      {
        const tokenClient = axios.create({
          baseURL: 'https://api.veritrans.co.jp',
          ...apiConfig,
        })
  
        const form = {
          card_number: cardNumber.replace(/\s/g, ''),
          card_expire: expiaryDate.replace(/\s/g, ''),
          security_code: cvc,
          token_api_key: token_api_key,
          cardholder_name: name,
          lang: 'ja',
        }
        console.info(JSON.stringify(form))
    
        const tokenResponse = await tokenClient.post('/4gtoken', form)
        console.info(tokenResponse)
  
        {
          const data = tokenResponse.data
          if (data == null) {
            setErrorMessage(<>通信エラーが発生しました<br/><br/>続くようなら、お手数ですが<a target="_blank" href="/contact">コンタクトフォーム</a>よりご連絡ください</>)
            submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
            return
          }

          const status = data.status 
          if (status != "success") {
            console.log("error=" + data.code + ":" + data.message)
            setErrorMessage(<>決済エラーが発生しました。番号や有効期限をご確認ください。</>)
            submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
            return
          }
        }

        token = tokenResponse.data.token;
        didGetToken({token:token, message:tokenResponse.data.message})
      }
  
      // 決済実行
      {
        const apiClient = axios.create({
          baseURL: api_base_url + '/purchase',
          //baseURL: api_base_url + 'purchase', // NG
          ...apiConfig,
        })
        
        const apiForm = {
          scoreId: score_id,
          quantity: 1,
          purchaseType: 'card',
          token: token,
        }
  
        const apiResponse = await apiClient.post('/', apiForm)
        //console.info(apiResponse)
  
        const data = apiResponse.data
        if (data == null || data.body == null || data.body.order_id == null) {
          setErrorMessage(<>エラーが発生しました<br/><br/>続くようなら、お手数ですが<a target="_blank" href="/contact">コンタクトフォーム</a>よりご連絡ください</>)
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
          return
        }

        didPayment({order_id:data.body.order_id})

        submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
      }
    } catch (e) {
      console.error(e)
      setErrorMessage(<>エラーが発生しました<br/><br/>続くようなら、お手数ですが<a target="_blank" href="/contact">コンタクトフォーム</a>よりご連絡ください</>)
      submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
      didError({error:e})
    }
  }

  // const popoverHoverFocus = (
  //   <Popover id="popover-trigger-hover-focus" title="CVC番号について" style={{padding:10, background:"#edf0f1", boxShadow:"rgb(170, 170, 170) 7px 7px 10px"}}>
  //     <div style={{fontSize:16, fontWeight:"bold"}}>CVC番号について</div>
  //     <div style={{textAlign:"left", fontSize:12, margin:"10px 0 0 0", lineHeight:"1.8em"}}>
  //       CVC番号とは、デビットカードやクレジットカードの裏面に記載されている3桁、または4桁の暗証番号です。<br /><br />
  //       詳しくは、<a style={{color:"#0083EA"}} target="_blank" href="https://help.piascore.com/hc/ja/articles/360057115271-CVC%E7%95%AA%E5%8F%B7%E3%81%A8%E3%81%AF%E3%81%AA%E3%82%93%E3%81%A7%E3%81%99%E3%81%8B-">CVC番号とはなんですか？</a> をご参照ください。 
  //     </div>
  //   </Popover>
  // );

  const handleClick = e => {
    //console.log("test2 " + e);
    setIsOpenCVCPopover(!isOpenCVCPopover)
    setCvcPopoverTarget(e.target)
  };

  return (
    <>
      <div className={styles.form}>

        <Form onSubmit={handleSubmit}>
          
          <Row className={styles.row}>
            <Col style={{textAlign:"center"}}>
              <img src="https://store.piascore.com/images/credit_cards.webp" />
            </Col>
          </Row>

          <Row className={styles.row} style={{marginTop:20, width:"100%"}}>
            <Col className={`${styles.col}`}>
              <Form.Group className="d-flex">
                <Form.Label className={styles.left}>カード番号</Form.Label>
                <div style={{width:250}}>
                  <div className="d-flex flex-wrap">
                    <div>
                      <Form.Control style={{width:232, height:38}} {...getCardNumberProps({ onChange: onChangeCardNumber })} isInvalid={touchedInputs.cardNumber && erroredInputs.cardNumber} placeholder="1111 2222 3333 4444" />
                      <svg style={{position:"relative", top:"-2px", left:"-25px"}} {...getCardImageProps({ images })} />
                    </div>
                    <div style={{width:"100%"}}></div>
                    <Form.Control.Feedback className={styles.feedback} type="invalid">{erroredInputs.cardNumber}</Form.Control.Feedback>
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className={styles.row}>
            <Col className={`${styles.col}`}>
              <Form.Group className="d-flex">
                <Form.Label className={styles.left}>有効期限</Form.Label>
                <div className="d-flex flex-wrap" style={{width:90}}>
                  <Form.Control style={{width:90, height:38}} {...getExpiryDateProps({ onChange: onChangeExpiryDate })}  isInvalid={touchedInputs.expiryDate && erroredInputs.expiryDate} placeholder="月 / 年" />
                  <div style={{width:"100%"}}></div>
                  <Form.Control.Feedback className={styles.feedback} type="invalid">{erroredInputs.expiryDate}</Form.Control.Feedback>
                </div>
              </Form.Group>

              {/* <Col style={{padding:0}}> */}
              <Form.Group className="d-flex" style={{marginLeft:20}}>
                <Form.Label>CVC</Form.Label>
                <div className="d-flex flex-wrap" style={{width:100, marginLeft:3}}>
                  <Form.Control style={{width:84, height:38}} {...getCVCProps({ onChange: onChangeCVC })} isInvalid={touchedInputs.cvc && erroredInputs.cvc} placeholder="123"/>
                  {/* <a style={{position:"relative", top:10, left:"-24px"}} data-bs-toggle="modal" data-bs-target="#cvcModal">
                    <FontAwesomeIcon icon="fa-regular fa-circle-question" size="xs" style={{width:16, height:16, marginRight:0, color: "#757575",}} />
                  </a> */}

                    <a style={{position:"relative", top:10, left:"-24px", height:20}} onClick={handleClick} ><FontAwesomeIcon icon="fa-regular fa-circle-question" size="xs" style={{width:16, height:16, marginRight:0, color: "#757575",}} /></a>

                  <Overlay
                    show={isOpenCVCPopover}
                    target={cvcPopoverTarget}
                    placement="top"
                    container={this}
                    containerPadding={20}
                  >
                    <Popover id="popover-trigger-hover-focus" title="CVC番号について" style={{padding:10, background:"#edf0f1", boxShadow:"rgb(170, 170, 170) 7px 7px 10px"}}>
                      <div style={{fontSize:16, fontWeight:"bold"}}>CVC番号について</div>
                      <div style={{textAlign:"left", fontSize:12, margin:"10px 0 0 0", lineHeight:"1.8em"}}>
                        CVC番号とは、デビットカードやクレジットカードの裏面に記載されている3桁、または4桁の暗証番号です。<br /><br />
                        詳しくは、<a style={{color:"#0083EA"}} target="_blank" href="https://help.piascore.com/hc/ja/articles/360057115271-CVC%E7%95%AA%E5%8F%B7%E3%81%A8%E3%81%AF%E3%81%AA%E3%82%93%E3%81%A7%E3%81%99%E3%81%8B-">CVC番号とはなんですか？</a> をご参照ください。 
                      </div>
                    </Popover>
                  </Overlay>

                  <div style={{width:"100%"}}></div>
                  <Form.Control.Feedback className={styles.feedback} type="invalid">{erroredInputs.cvc}</Form.Control.Feedback>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className={styles.row}>
            {/* <Form.Group className={styles.left} > */}
              <Col className={`${styles.col}`}>
                <Form.Label>カード名義</Form.Label>
                <div style={{display:"inline-flex", width:"calc(100% - 80px)"}} className={styles.value}>
                  <Form.Control type="text" onChange={onChangeName} style={{boxShadow:"inset 0px 1px 2px #e5e5e5", height:38}} placeholder="HANAKO YAMADA" />
                </div>
              </Col>
            {/* </Form.Group> */}
          </Row>

          <Row style={{"margin":"15px 0px", display:(errorMessage ? "block" : "none")}}>
            <Col className="">
              <div style={{border:"1px solid #ff0000", color:"#ff0000", background:"#FFE9E9", padding:20}}>
                {errorMessage}
              </div>
            </Col>
          </Row>
          
          <Row style={{"margin":"15px 0px"}}>
            <Col className="d-flex justify-content-center">
              <Button disabled={disabledSubmit} onClick={handleSubmit} variant="primary" style={{width:"100%", height:56}}>
              カードで支払う
              </Button>
            </Col>
          </Row>

        </Form>
      
      </div>

      { /* CVCモーダル */}
      {/* <div className="modal fade" id="cvcModal" tabIndex="-1" aria-labelledby="cvcModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{zIndex:20000}}>
            <div className="modal-header">
              <h5 className="modal-title" id="cvcModalLabel"><FontAwesomeIcon icon="fa-regular fa-circle-question" size="xs" style={{width:16, marginRight:0, color: "#757575",}} /> CVC番号について</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            <div className="modal-body" style={{textAlign:"left"}}>
              <div style={{fontSize:12}}>
                CVC番号とは、デビットカードやクレジットカードの裏面に記載されている3桁、または4桁の暗証番号です。<br /><br />
                詳しくは、<a style={{color:"#0083EA"}} target="_blank" href="https://help.piascore.com/hc/ja/articles/360057115271-CVC%E7%95%AA%E5%8F%B7%E3%81%A8%E3%81%AF%E3%81%AA%E3%82%93%E3%81%A7%E3%81%99%E3%81%8B-">CVC番号とはなんですか？</a> をご参照ください。 
              </div>
            </div>

            <div className="modal-footer" style={{justifyContent:"center"}}>
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" style={{width:"100%", margin:"0px 20px"}}>閉じる</button>
            </div>
          </div>
        </div>
      </div> */}

    </>
  )
}
