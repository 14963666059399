import React, { useEffect, useState, useRef } from 'react';
import styles from './PSStoreHeaderComponent.module.scss'
import PSStoreFavoriteListButtonComponent from './score/PSStoreFavoriteListButtonComponent';
import PSStoreMiscData from './common/PSStoreMiscData';

export default function PSStoreHeaderComponent({ keyword, instrument, mail_address, api_base_url }) {
  const Header = {pcLarge: 0, pcVariable: 1, pcSmall: 2, mobile: 3};
  const [headerMode, setHeaderMode] = useState(Header.pcLarge);
  const [headerHeightPC, setHeaderHeightPC] = useState(null);

  const headerMaxHeight = 95;
  const headerMinHeight = 61;
  const targetZoom = 0.8;
  const targetZoomLogo = 0.64;
  const originTop = 35;
  const moblileWidthThreshold = 600;

  const recommend_keywords = PSStoreMiscData.recommend_keywords;
  const instrument_options = PSStoreMiscData.simple_json_instruments;

  const isMobile = () => headerMode === Header.mobile;
  const updateHeaderStyle = () => {
    if (window.innerWidth <= moblileWidthThreshold) {
      setHeaderMode(Header.mobile);
      setHeaderHeightPC(null);
    } else {
      const scrollTop = window.scrollY;
      let height = headerMaxHeight - (scrollTop - originTop);
      if (height < headerMinHeight) height = headerMinHeight;
      if (height > headerMaxHeight) height = headerMaxHeight;
      setHeaderHeightPC(height);

      if (scrollTop < originTop) {
        setHeaderMode(Header.pcLarge);
      } else if (scrollTop < headerMinHeight) {
        setHeaderMode(Header.pcVariable);
      } else {
        setHeaderMode(Header.pcSmall);
      }
    }
  }

  const zoomStyle = (zoom) => {
    if (isMobile())  return {};
    const z = (1.0 - zoom) / (headerMaxHeight - headerMinHeight) * (headerHeightPC - headerMinHeight) + zoom;
    return {zoom: z};
  };

  useEffect(() => {
    updateHeaderStyle();
    window.addEventListener('scroll', updateHeaderStyle);
    window.addEventListener('resize', updateHeaderStyle);
    return () => {
      window.removeEventListener('scroll', updateHeaderStyle);
      window.removeEventListener('resize', updateHeaderStyle);
    };
  }, []);


  const HeaderTop = () => (
    <div className={styles.header_top}>
      <div className={styles.about_page}>楽譜を1曲から購入！タブレットとの共有も簡単！</div>
      <div className={styles.howto}>
        <a href="/howto">
          <span className="fas fa-shopping-bag"></span>
          <span className={styles.title}>楽譜の購入方法</span>
        </a>
        {!isMobile() &&
        <a className={styles.publisher} href="https://publish.piascore.com/">
          <span className="fas fa-arrow-circle-right"></span>
          <span className={styles.title}>販売者の方はこちら</span>
        </a>}
      </div>
    </div>
  );

  const HeaderContent = () => (
    <div className={(headerMode === Header.pcVariable || headerMode == Header.pcSmall) ?
        styles.header_content_sticky : styles.header_content}
        style={{height: headerHeightPC}}>
      <div className={styles.container}>
        <LogoLetter />
        {!isMobile() &&
          <SearchBox recommendKeywords={headerMode !== Header.pcSmall && recommend_keywords}/>
        }
        <UserLinks />
      </div>
      {isMobile() &&
        <div>
          <SearchBox recommendKeywords={false} />
        </div>
      }
    </div>
  );

  const LogoLetter = () => (
    <div className={styles.logo_letter} style={zoomStyle(targetZoomLogo)}>
      <a className={styles.link_container} href="/">
        <div>
          <img className={styles.image} src="/assets/webstore_logo.png" alt="Webstore logo" />
          <div className={styles.letter}>
            <div className={styles.piascore}>Piascore</div>
            <div className={styles.scoreshop}>楽譜ストア</div>
          </div>
        </div>
      </a>
    </div>
  );

  const RecommendKeywords = ({recommendKeywords}) => (
    <div className={styles.recommend_keywords}>
      <span className="fas fa-fire-alt">注目</span>
      {recommendKeywords.map((keyword, index) => (
        <a key={index} className={styles.keyword} href={`/search?${keyword.p}`}>
          {keyword.k}
        </a>
      ))}
    </div>
  );

  const SearchBox = ({recommendKeywords}) => {
    const selectForm = useRef(null);
    const inputForm = useRef(null);
    return (
      <div className={styles.search_box} style={zoomStyle(targetZoom)}>
        <div className={styles.container}>
          <div className={styles.search_group}>
            <form method="get" action="/search" id="keyword_form" acceptCharset="UTF-8" ref={selectForm}>
              <select name="i" className="form-select" defaultValue={instrument}
                onChange={_ => selectForm.current.submit()}>
                <option value="">すべての楽器</option>
                {instrument_options.map((inst, index) => (
                  <option key={index} value={inst.instrument_id}>
                    {isMobile() && (inst.instrument_id !== instrument) && (inst.emoji || "　")} {inst.name}
                  </option>
                ))}
              </select>
              <input type="search" name="n" id="n" defaultValue={keyword} autoFocus={false}
                placeholder=" 曲名、アーティストなど" ref={inputForm} />
              {isMobile() &&
                <a onClick={_ => inputForm.current.value = ""}>
                  <i className="fas fa-times" />
                </a>}
              <input type="submit" value="検索" className="btn btn-light" />
            </form>
            {recommendKeywords && <RecommendKeywords recommendKeywords={recommendKeywords} />}
          </div>
        </div>
      </div>
    );
  }
  const UserLinks = () => (
    <div className={styles.user_links} style={zoomStyle(targetZoom)}>
      {mail_address ? (
        <>
          <div className={styles.fav_button}>
            <PSStoreFavoriteListButtonComponent isLogin={true} image_fqdn="https://image.piascore.com" api_base_url={api_base_url} />
          </div>
          <div className={styles.user_name}>
            <a className={styles.user_link_bold} href="/user">{mail_address.split('@')[0]}さん</a>
          </div>
        </>
      ) : (
        <div className={styles.user_box}>
          <a className={styles.user_link_bold} href="/sign_up">ユーザー登録</a>
          <a className={styles.user_link} href="/login">ログイン</a>
        </div>
      )}
    </div>
  );


  const Assistant = () => (
    <div className={styles.sticker_assistant}>
    </div>
  );

  return (
    <div>
      <HeaderTop />
      <HeaderContent />
      <Assistant />
    </div>
  );
};
