import React from 'react';
import { useState, useEffect, useRef } from 'react'

import { Container, Row, Col, Form, Button, Card, Table, Popover, OverlayTrigger, ListGroup, Pagination } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import PSAPIClient    from '../common/PSAPIClient.js'

import styles from './PSStoreFavoriteListButtonComponent.module.scss'

async function loadFavorite({api_base_url, pageIndex=1, pageSize=10}) {
  try {
    //const url = api_base_url + `/bookmark?page=${pageIndex}&num=${pageSize}`
    const url = api_base_url + `/bookmark/?page=${pageIndex}&num=${pageSize}`
    const apiResponse = await PSAPIClient.get({url})
    //console.log(apiResponse)
  
    const resBody = apiResponse.body
    //console.log(resBody)
    
    return resBody
    //return resBody.score/s
    // setScoresState(resBody.scores)
  } catch (e) {
    console.error(e)
    console.info(e.response.data)
    throw e
  }
}

export default function PSStoreFavoriteListButtonComponent({ isLogin, image_fqdn, api_base_url }) {

  const [scoresState, setScoresState]   = useState(null)
  const [numberOfFavorites, setNumberOfFavorites]  = useState(0)
  const [pageIndex, setPageIndex]       = useState(1)

  const PAGE_SIZE = 10

  useEffect(() => {
    (async () => {
    })()
  }, [])
  
  const mainPerson = function(score) {
    if(score.artists.length > 0) { return score.artists[0].regions.ja.name }
    if(score.composers.length > 0) { return score.composers[0].regions.ja.name }

    return ""
  } 
  
  const handleClick = async ({pageIndex}) => {
    //console.log(event.target.value)
 
    //const pageIndex = parseInt(event.target.value)
    console.log(pageIndex)
    
    const favJSON = await loadFavorite({api_base_url:api_base_url, pageIndex:pageIndex, pageSize:PAGE_SIZE})
    
    setPageIndex(pageIndex)
    setNumberOfFavorites(favJSON.count)
    setScoresState(favJSON.scores)
    
    const element = document.getElementById("scrollDiv");
    element.scrollTop = 0;
  };

  const popoverBottom = (
    <Popover style={{width:"100%", boxShadow: "0px 5px 30px rgba(0, 0, 0, .4)"}} id="popover-positioned-bottom" title="Popover bottom">
      <Card>
        <Card.Header style={{textAlign:"center", fontWeight:"bold"}}>お気に入り</Card.Header>
        <Card.Body id="scrollDiv" style={{maxHeight:"calc(100vh - 140px)", overflow:"auto", padding:0}}>
          {
            scoresState != null ?
              scoresState.length > 0 ?
              (
                <>
                  <div style={{padding:10, fontSize:12, color:"#999"}}>
                  {(pageIndex-1)*PAGE_SIZE+1}-{Math.min((pageIndex)*PAGE_SIZE, numberOfFavorites)}楽譜 / 全{numberOfFavorites}楽譜
                  </div>    
                  <ListGroup variant="flush">
                    {
                      scoresState.map((score, index) => {
                        return (
                          <ListGroup.Item style={{padding:"10px 10px 10px 0"}} key={"score-" + index}>
                            <a href={`/scores/${score.score_id}`} className={styles.score} style={{display:"block", fontSize:10, color:"#B5B5B5"}}>
                              <div style={{display:"flex"}}>
                                <div style={{width:73, textAlign:"center"}}>
                                  <div><img src={`${image_fqdn}/store/coverimage/site_l/${score.management_id}.png`} style={{width:59, border: "0.5px solid #D8D8D8"}} /></div>
                                  <div style={{margin:"5px 0 0 0"}}>{ score.score_id }</div>
                                </div>
                                                      
                                <div style={{width:"calc(100% - 73px)"}}>
                                  <div style={{fontSize:12, color:"#0083EA"}}>{score.regions.ja.name}</div>

                                  <div style={{color:"#000000", margin:"2px 0 0 0"}}>
                                    { mainPerson(score) }
                                  </div>
            
                                  <div style={{margin:"6px 0 0 0", display:"flex", alignItems:"flex-start"}}>
                                    <div style={{color:"#3B6015", border:"1px solid #3B6015", margin:"0 4px 0 0", padding:"2px 4px", borderRadius:3, maxWidth:90}}>{score.instrument.regions.ja.name}</div>
                                    <div style={{color:"#9F5519", border:"1px solid #9F5519", margin:"0 4px 0 0", padding:"2px 4px", borderRadius:3, maxWidth:60}}>{score.difficulty.regions.ja.name}</div>
                                    { score.regions.ja.video_url ? (<div style={{color:"#FFFFFF", background:"#E4351C", padding:"3px 4px", borderRadius:3}}>動画</div>) : "" }
                                  </div>
                                  
                                  <div style={{margin:"6px 0 0 0", display:"flex", alignItems:"center"}}>
                                    <div style={{flex:1}}>{score.page_num}ページ</div>
                                    <div style={{fontSize:12, color:"#B54725"}}>¥{score.distribute.jpy_price_with_tax.toLocaleString()}</div>
                                  </div>
                                  
                                  <div style={{margin:"6px 0 0 0"}}>
                                    <div><FontAwesomeIcon style={{color:"#EAB500", marginRight:3}} icon="fa-solid fa-store" />{score.publishers[0].regions.ja.name}</div>
                                  </div>
                                </div>

                              </div>
                            </a>
                          </ListGroup.Item>
                        )
                      })
                    }
                  </ListGroup>
                  
                  <div style={{margin:"20px 0 0 0", fontSize:10}}>
                    <Pagination style={{justifyContent:"center"}}>
                      { pageIndex !=1 ? (<Pagination.First onClick={ () => handleClick({pageIndex:(1)})} />) : "" }
                      { pageIndex !=1 ? (<Pagination.Prev onClick={ () => handleClick({pageIndex:(pageIndex-1)})} />) : "" }

                      { pageIndex !=1 ? (<Pagination.Item onClick={ () => handleClick({pageIndex:(pageIndex-1)})}>{pageIndex-1}</Pagination.Item>) : "" }
                      <Pagination.Item onClick={ () => handleClick({pageIndex:(pageIndex)})} active>{pageIndex}</Pagination.Item>
                      { pageIndex < (Math.ceil(numberOfFavorites/PAGE_SIZE)) ? (<Pagination.Item onClick={ () => handleClick({pageIndex:(pageIndex+1)}) } >{pageIndex+1}</Pagination.Item>) : "" }

                      { pageIndex < (Math.ceil(numberOfFavorites/PAGE_SIZE)) ? (<Pagination.Next onClick={ () => handleClick({pageIndex:(pageIndex+1)})} />) : "" }
                      { pageIndex < (Math.ceil(numberOfFavorites/PAGE_SIZE)) ? (<Pagination.Last onClick={ () => handleClick({pageIndex:(Math.ceil(numberOfFavorites/PAGE_SIZE))})} />) : "" }
                    </Pagination>
                  </div>
                </>
              )
              :
              <div style={{padding:"20px 0", textAlign:"center"}}>
                お気に入りがありません
              </div>
            :
            ""
          }
        </Card.Body>
      </Card>
    </Popover>
  );
    
  return (
    <>
      <OverlayTrigger trigger={['click']} rootClose placement="bottom" overlay={popoverBottom}>
        
        <Button className={styles.fav} style={{}} onClick={() => handleClick({pageIndex:pageIndex})}>
          <FontAwesomeIcon icon="fa-solid fa-heart" /> 
          {/* お気に入り */}
        </Button>
      </OverlayTrigger>
    </>
  )
}