import React from 'react';
import axios from 'axios';

import './PopularSearchKeyword.scss'; 

class PopularSearchKeyword extends React.Component {
  //type Props = { api_url: string }

  state = {
    keywords: []
  }

  componentDidMount() {
    let api_url = this.props.api_base_url + "/scores/keywords";
    axios.get(api_url, {withCredentials:true})
      .then(res => {
        //console.log(res.data.body);
        const ks = res.data.body.popular;
        this.setState({ keywords:ks });
      })
  }

  render() {
    return (
      <div className="PopularSearchKeyword">
        {
          this.state.keywords
          .map((keyword, index) =>
            <a key={index} href={"/search?n="+keyword}>{keyword}</a>
          )
        }
      </div>
    )
  }
}

export default PopularSearchKeyword;