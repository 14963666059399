import React from 'react';
import { useState, useRef, useEffect } from 'react'

//import { Alert } from 'react-bootstrap'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library }         from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)

import PSAPIClient    from '../common/PSAPIClient.js'

import styles from './PSStoreFavoriteButtonComponent.module.scss'

//const { Toast, Alert } = bootstrap
import { Toast, ToastContainer, Row, Col, Button } from 'react-bootstrap'

export default function PSStoreFavoriteButtonComponent({ isFavorite, scoreId, api_base_url }) {

  const [isFavoriteState, setIsFavoriteState]   = useState(isFavorite)
  const [notificationText, setNotificationText]   = useState("")

  const [show, setShow] = useState(false);

  const [bounce, setBounce] = useState(false)
  const [fade, setFade] = useState(false)
  
  const onClick = async function(e) {
    console.log(e)
    
    const url = api_base_url + `/bookmark/${scoreId}`
    if(isFavoriteState) {
      const apiResponse = await PSAPIClient.delete({url, data:null})
      setIsFavoriteState(false)
      setNotificationText("💔 お気に入りを外しました")

      setShow(true)
      
      setFade(true)
      setTimeout(() => {
        setFade(false)
      }, 1000)
    }else {
      const apiResponse = await PSAPIClient.put({url, data:null})
      setIsFavoriteState(true)
      setNotificationText("🧡 お気に入りに追加しました")

      setShow(true)
      
      setBounce(true)
      setTimeout(() => {
        setBounce(false)
      }, 1000)
    }
  }
  
  return (
    <>
      <div>
        <div>
          <button className={`${styles.button} ${isFavoriteState ? styles.fav : styles.nofav}`} onClick={e => onClick(e)  } >
            {
              isFavoriteState ? ( 
                <>
                  <FontAwesomeIcon style={{zIndex:9999}} icon="fa-solid fa-heart" bounce={bounce} /> 
                </>
              )
              :
              ( 
                <>
                  <FontAwesomeIcon icon="fa-regular fa-heart" fade={fade} /> 
                </>
              )
            }
          </button>
        </div>
      </div>

      <div className="position-fixed top-0 start-0" style={{width:"100vw"}} >
        <Toast style={{margin:"30px auto 0", borderRadius:"0.45rem", overflow:"hidden", /*borderBottom:"4px solid #007e19"*/}} onClose={() => setShow(false)} show={show} delay={3000} autohide>
          <Toast.Header closeButton={false} style={{padding:"20px 20px", color:"#FFFFFF", background:"linear-gradient(135deg, #40bc00, #009e00)"}}>
            { notificationText }
          </Toast.Header>
          {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
        </Toast>
      </div>
    </>
  )
}

