import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styles from './PSStoreSearchComponent.module.scss';
import PSAPIClient from './common/PSAPIClient';
import PopularSearchKeyword from './PopularSearchKeyword';

const PiascoreScoresSearchApiUrl = "https://api.piascore.com/scores/search";
const ValidScoresSearchQuery = ['n', 'i', 'g', 'c', 'd', 'p', 'page'];
const ScoreNumPerPage = 20;
const CompanyIconIds = [8, 115, 131, 138, 144, 160, 178, 261, 314, 363, 393, 446, 449, 468, 539, 635, 724, 821, 823, 849, 912, 1042, 1320, 1781, 2133, 2544, 3390, 3732];
const ImageProtocolFqdn = "https://image.piascore.com";
const CompanyIconUrl = `${ImageProtocolFqdn}/company/icons`;
const CoverImageUrl = `${ImageProtocolFqdn}/store/coverimage/site_l`;
const MoblileWidthThreshold = 600;
const ParamKeys = "nigpcd";
const ParamKey2IdKey = {i: "instrument_id", g: "genre_id", p: "person_id", c: "company_id", d: "difficulty_id"};
const ParamKey2Title = {i: "楽器", g: "ジャンル", p: "アーティスト", c: "販売者", n: "キーワード", d: "難易度"};

const genreId = (v) => v.code1 + (v.code2 === "000" ? "" : v.code2) + (v.code3 === "000" ? "" : v.code3);

const scoresSearch = async (query) => {
  const queryKeys = Object.keys(query).filter(k => ValidScoresSearchQuery.includes(k)).sort();
  const params = queryKeys.map(key => `${key}=${encodeURI(query[key])}`).concat(`num=${ScoreNumPerPage}`).join('&')

  return await PSAPIClient.get({url: `${PiascoreScoresSearchApiUrl}?${params}`})
}

const paginationList = (currentPage, totalItems, pageSize) => {
  const listLength = Math.floor((totalItems + pageSize - 1) / pageSize);
  let list = [];

  if (listLength === 1) {
    list = [1];
  } else if (listLength === 2) {
    if (currentPage === 1) {
      list = [1, 2, ">"];
    } else {
      list = ["<", 1, 2];
    }
  } else if (listLength === 3) {
    if (currentPage === 1) {
      list = [1, 2, 3, ">", "≫"];
    } else if (currentPage === 2) {
      list = ["<", 1, 2, 3, '>'];
    } else {
      list = ["≪", "<", 1, 2, 3];
    }
  } else {
    if (currentPage === 1) {
      list = [1, 2, 3, ">", "≫"];
    } else if (currentPage === 2) {
      list = ["<", 1, 2, 3, 4, ">", "≫"];
    } else if (currentPage === listLength - 1) {
      list = ["≪", "<", listLength - 3, listLength - 2, listLength - 1, listLength, ">"];
    } else if (currentPage === listLength) {
      list = ["≪", "<", listLength - 2, listLength - 1, listLength];
    } else {
      list = ["≪", "<", currentPage - 2, currentPage - 1,
        currentPage, currentPage + 1,  currentPage + 2, ">", "≫"];
    }
  }
  const pages = list.map(p => {
    switch(p) {
      case '≪':
        return {display: p, page: 1, selected: false};
      case '≫':
        return {display: p, page: listLength, selected: false};
      case '<':
        return {display: p, page: currentPage - 1, selected: false};
      case '>':
        return {display: p, page: currentPage + 1, selected: false};
      default:
        return {display: String(p), page: p, selected: p === currentPage};
    }
  });

  return pages;
}


function CompanyIcon({companyId, noIconPad}) {
  if (CompanyIconIds.includes(companyId)) {
    return <img src={`${CompanyIconUrl}/${companyId}_400_400.jpg`}  className={styles.company_icon}/>;
  }
  if (noIconPad) {
    return <span className={styles.no_company_icon}></span>;
  }
  return null;
}


export default function PSStoreSearchComponent({query, pathname, api_base_url}) {

  const [scores, setScores] = useState([]);
  const [facet, setFacet] = useState([]);
  const [queries, setQueries] = useState([]);
  const [pageStatus, setPageStatus] = useState({});
  const [showMobileFacet, setShowMobileFacet] =  useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const updateScreenWidth = () => {
    setIsMobile(window.innerWidth <= MoblileWidthThreshold);
  }

  useEffect(() => {
    (async() => {
      const result = await scoresSearch(query);
      if (result.status.success === 1) {
        // console.log("===", query, result);
        setScores(result.body.scores);
        setFacet(result.body.facet);
        setQueries(result.body.query);
        setPageStatus(result.body.status);
      } else {
        console.log("*** Error ", result.status);
        setScores([]);
        setFacet([]);
        setQueries([]);
        setPageStatus({res_num: 0});
      }
    })();
  }, [query]);

  useEffect(() => {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);


  return (
    <div className={styles.container}>
      <NavigationBar queries={queries} pathname={pathname} onMobileFacet={isMobile ? _ => setShowMobileFacet(true) : null} />
      <div className={styles.body}>
        {!isMobile && <Facet facet={facet} query={query} pathname={pathname} blank={scores?.length == 0} />}
        <div className={styles.result}>
          <Queries queries={queries} pathname={pathname} pageStatus={pageStatus}/>
          {pageStatus.res_num > 0 ?
            <Pagenation pageStatus={pageStatus} query={query} pathname={pathname}>
              <ScoreList scores={scores} />
            </Pagenation> :
            <NoResult api_url_base={api_base_url} />
          }
        </div>
      </div>
      <OffcanvasFacet facet={facet} query={query} pathname={pathname} show={showMobileFacet} onHide={_ => setShowMobileFacet(false)} />
     </div>
  );
}

function OffcanvasFacet({facet, query, pathname, show, onHide}) {
  return (
    <Offcanvas show={show} onHide={onHide}
      placement="end" className={`shadow ${styles.container} ${styles.offcanvas_facet}`}>
      <Offcanvas.Header closeButton className={styles.header}>
        <span><i className="fas fa-filter" />絞り込み</span>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className={styles.body}>
          <Facet facet={facet} query={query} pathname={pathname} />
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}

function NavigationBar({queries, pathname, onMobileFacet}) {
  const link = (k, v) => pathname + "?" + `${k}=${encodeURI(v)}`;

  function Dot({param}) {
    let paramKeyArray = ParamKeys.split('');
    const nextKeys = paramKeyArray.splice(ParamKeys.indexOf(param) + 1);
    const hasNext = nextKeys.find(k => queries[k]);
    return (
      queries[param] && hasNext && "・"
    )
  }

  function LinkIds({param}) {
    if (!queries[param]) { return  null; }
    return (
      <>
        {queries[param].map((v, ix) =>
          <a href={link(param, v[ParamKey2IdKey[param]])} key={ix}>{v.regions.ja.name}</a>)}
        <Dot param={param} />
      </>
    );
  }

  function LinkGenre({param}) {
    if (!queries[param]) { return  null; }
    return (
      <>
        {queries[param].map((v, ix) =>
          <a href={link(param, genreId(v))} key={ix}>{v.regions.ja.name}</a>)}
        <Dot param={param} />
      </>
    );
  }

  function LinkText({param}) {
    if (!queries[param]) { return  null; }
    return (
      <>
        <a href={link(param, queries[param])}>{queries[param]}</a>
        <Dot param={param} />
      </>
    );
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light shadow" style={{width: '100%'}}>
      <div className={`container-fluid ${styles.search_query_bar}`}>
        <h1 className={styles.breadcrumbs}>
          <span className={styles.dots} >
            <LinkText param="n" />
            <LinkIds param="i" />
            <LinkIds param="p" />
            <LinkGenre param="g"/>
            <LinkIds param="c" />
            <LinkIds param="d" />
           </span>
          {Object.keys(queries).length > 0 && <span> 楽譜一覧</span>}
        </h1>
        {onMobileFacet &&
          <div>
            <button className={`btn btn-outline btn-sm ${styles.refine_button}`} type="button"
                onClick={e => {e.preventDefault(); onMobileFacet()}}>
              絞り込み
            </button>
          </div>
        }
      </div>
    </nav>
  );
}


function Facet({facet, query, pathname, blank}) {
  if (facet.length === 0) {
    return (<div className={styles.facet}></div>);
  }
  if (blank) {
    return (<div className={styles.facet}><div style={{padding: '20px 0'}} /></div>);
  }

  const linkUrl = (key, value) => {
    let q = {...query, [key]: value};
    delete q.page;   //remove page parameter
    return pathname + "?" + Object.keys(q).sort().map(key => `${key}=${encodeURI(q[key])}`).join('&');
  }

  const linkNullUrl = (key) => {
    let q = {...query};
    delete q.page;   //remove page parameter
    delete q[key];      //remove instrument_id parameter
    return pathname + "?" + Object.keys(q).sort().map(key => `${key}=${encodeURI(q[key])}`).join('&');
  }

  const FacetCount = ({count}) => (
    <span className={styles.num}>
      {`（${Number(count).toLocaleString('ja-JP')}）`}
    </span>
  )

  const FacetListHeader = ({param}) => {
    return (
      <>
        <h2 className={styles.facet_title} >{ParamKey2Title[param]}</h2>
        {query[param] &&
          <div className={styles.facet_list}>
            <span className={styles.name}>
              <span className={styles.all_head}>&lt; </span>
              <a href={linkNullUrl(param)}>全て</a>
            </span>
          </div>
        }
      </>
    );
  }

  const SimpleFacetList = ({param, values}) => {
    if (values === null) { return null; }
    return (
      <div className={styles.facet_block} >
        <FacetListHeader param={param} />
        {values.map((value, ix) =>
          <div className={styles.facet_list} key={ix}>
            <span className={styles.name}>
              <a href={linkUrl(param, value[ParamKey2IdKey[param]])}>
                {value.regions.ja.name}
              </a>
            </span>
            <FacetCount count={value.num} />
          </div>
        )}
      </div>
    )
  };

  const ExtraFacetList = ({param, values, extraItem}) => {
    if (values === null) { return null; }
    return (
      <div className={styles.facet_block} >
        <FacetListHeader param={param} />
        {values.map((value, ix) =>
          <div className={styles.facet_list} key={ix}>
            <span className={styles.name}>
             {extraItem(value)}
            </span>
            <FacetCount count={value.num} />
          </div>
        )}
      </div>
    )
  };


  const GenresFacetList = ({values}) => {
    const [showSubList, setShowSubList] = useState([...Array(values?.length ?? 0)]);

    const invertList = (list, ix) => {
      let listCopy = [...list];
      listCopy.splice(ix, 1, !list[ix]);
      return listCopy;
    };

    if (values === null) { return null; }
    return (
      <div className={styles.facet_block} >
        <FacetListHeader  param="g" />
        {values.map((value, ix) =>
          <div className={styles.facet_list} key={ix}>
            <span className={styles.name}>
            {value?.genres2.length > 0 ?
              <i className={`far fa-plus-square ${styles.mark}`}
                onClick={_ => setShowSubList(list => invertList(list, ix))} /> :
              <i className={styles.no_mark} />}
              <a href={linkUrl('g', value.code1)}>
                {value.regions.ja.name}
              </a>
            </span>
            <FacetCount count={value.num} />
            {value?.genres2.length > 0 && showSubList[ix] &&
              <ul className={styles.genre2_sub_list}>
              {value.genres2.map((value2, ix) =>
                <li className={styles.facet_list} key={ix}>
                  <span className={styles.name}>
                    <a href={linkUrl('g', value.code1 + value2.code2)}>
                      {value2.regions.ja.name2}
                    </a>
                  </span>
                  <FacetCount count={value2.num} />
                  {value2?.genres3.length > 0 && showSubList[ix] &&
                    <ul className={styles.genre3_sub_list}>
                    {value2.genres3.map((value3, ix) =>
                      <li className={styles.facet_list} key={ix}>
                        <span className={styles.name}>
                          <a href={linkUrl('g', value.code1 + value2.code2 + value3.code3)}>
                            {value3.regions.ja.name3}
                          </a>
                        </span>
                        <FacetCount count={value3.num} />
                      </li>
                    )}
                    </ul>
                  }
                </li>
              )}
              </ul>
            }
          </div>
        )}
      </div>
    );
  }

  const difficultieItem = (value) =>
    value.num === 0 ?
    (<span className={styles.disabled}>{value.regions.ja.name}</span>) :
    (<a href={linkUrl('d', value.difficulty_id)}>{value.regions.ja.name}</a>);

  const companyItem = (value) => (
    <a href={linkUrl('c', value.company_id)}>
      <CompanyIcon companyId={value.company_id} noIconPad={true} />
      {value.regions.ja.name}
    </a>);

  const difficulties = facet.difficulties.find(d => d.num > 0) ? facet.difficulties : null;

  return (
    <div className={styles.facet}>
      <div className={styles.body}>
        <SimpleFacetList param="i" values={facet.instruments} />
        <SimpleFacetList param="p" values={facet.people} />
        <ExtraFacetList param="d" values={difficulties} extraItem={difficultieItem}/>
        <GenresFacetList values={facet.genres1}/>
        <ExtraFacetList param="c" values={facet.publishers} extraItem={companyItem}/>
      </div>
    </div>
  );
}


function Queries({queries, pathname, pageStatus}) {
  const linkQueris =  Object.keys(queries).reduce((list, key) =>
    list.concat(
      key === 'n' ?
        [['n', queries[key]]] :
      key === 'g' ?
        queries[key].reduce((l, v) => l.concat([[key, genreId(v)]]), []) :
      queries[key].reduce((l, v) => l.concat([[key, v[ParamKey2IdKey[key]]]]), [])
    ), []);

  function LinkButton({param, value}) {
    if (!queries[param]) { return  null; }

    const link = pathname + "?" + linkQueris.filter(v => v[0] !== param).
      map(v => `${v[0]}=${encodeURI(v[1])}`).join('&');
    return (
      <a href={link} className={styles.item} >
        <span className={`${styles.icon} far fa-times-circle`}></span>
        <span className={styles.key}>{ParamKey2Title[param]}</span>
        <span className={styles.value}>{value}</span>
      </a>
    );
  }

  function LinkButtons({param}) {
    if (!queries[param]) { return  null; }
    return (
      queries[param].map((v, ix) =>
        <LinkButton param={param} value={v.regions.ja.name} key={ix} />)
    )
  }

  return (
    <>
      <div className={styles.criteria_block}>
        <div>
          <LinkButton param="n" value={queries.n} />
          <LinkButtons param="i" />
          <LinkButtons param="p" />
          <LinkButtons param="g" />
          <LinkButtons param="c" />
          <LinkButtons param="d" />
          <div className={styles.clear_both}></div>
        </div>

        {pageStatus.res_num > 0 &&
          <div className={styles.result_num}>
            <span>
              <span>{pageStatus.page}</span>
              ページ / 全
              <span>{pageStatus.res_num}</span>
              件
            </span>
          </div>
        }
      </div>
    </>
  );
}

function NoResult({api_url_base}) {
  return (
    <div className={styles.empty_score_list}>
      検索結果がありませんでした
      <br/>
      キーワードを変えて検索してみて下さい
      <PopularSearchKeyword api_base_url={api_url_base} />
    </div>
  );
}


function ScoreList({scores}) {

  const ScoreItem = ({score}) => {
    const image_path_png = `${CoverImageUrl}/${score.management_id}.png`;
    const image_path_webp = `${CoverImageUrl}/${score.management_id}.webp`;
    const name = score.regions.ja.name;
    const persons = score.artists?.length > 0 ? score.artists : scores.composers;
    const person_names = persons?.length > 0 ? persons[0].regions.ja.name : "";
    const videoUrl = score.regions.ja.video_url;
    const publisher = score.publishers?.length > 0 ? score.publishers[0] : null

    // console.log("++", score);
    return (
      <>
        <div className={styles.score_image}>
          <picture className="bookcover">
            <source srcSet={image_path_png} />
            <source srcSet={image_path_webp} />
            <img src={image_path_png} alt={name.replace(/"/g, " ")} />
          </picture>
        </div>
        <div className={styles.score_desc}>
          <div className={styles.title}>{name}</div>
          <div className={styles.persons}>{person_names}</div>
          <div className={styles.info}>
            <div className={styles.info_line1}>
              {score.instrument &&
                <div className={styles.instrument}>
                  {score.instrument.regions.ja.name}
                </div>
              }
              <div className={styles.difficulty}>
                {score.difficulty.regions.ja.name}
              </div>
              {videoUrl &&
                <div className={styles.movie}>
                  <span className="fas fa-play-circle" />
                  <span style={{marginLeft:2}}>動画</span>
                </div>
              }
            </div>
            <div className={styles.info_line2}>
              <div className={styles.page_num}>
                {score.page_num}ページ
              </div>
              <div className={styles.price}>
                ¥ {Number(score.distribute.jpy_price_with_tax).toLocaleString('ja-JP')}
              </div>
            </div>
            <div className={styles.publisher}>
              {publisher && <CompanyIcon companyId={publisher.company_id}/>}
              {publisher && publisher.regions.ja.name}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={styles.score_list}>
      {scores.map((score, ix) => (
        <a href={`/scores/${score.score_id}`} className={styles.displayed_score} key={ix}>
          <ScoreItem score={score} />
        </a>
      ))}
    </div>
  );
}

function Pagenation({pageStatus, children, query, pathname}) {
  const pages = paginationList(pageStatus.page, pageStatus.res_num , pageStatus.num);

  const link = (page) => {
    const q = {...query, page: page};
    return  pathname + "?" + Object.keys(q).map(key => `${key}=${encodeURI(q[key])}`).join('&');
  }

  return (
    <div>
      {children}
      <nav aria-label="Search results pages">
        <ul className="pagination" style={{justifyContent: 'center'}} >
          {pages.map((page, ix) =>
            <li className={page.selected ? styles.page_item_active : styles.page_item} key={ix}>
              <a href={link(page.page)} className={`page-link ${styles.page_link}`}>{page.display}</a>
            </li>)}
        </ul>
      </nav>
    </div>
  );
}


