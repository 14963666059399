import React from 'react';
import './PSStoreFooterComponent.scss';


const PSStoreFooterComponent = () => {
  return (
    <footer>
      <div style={{ width: '100%', margin: '50px 0 0 0' }}>
        <div style={{ height: '49px', borderTop: '1px solid #DFF1FF', background: 'linear-gradient(to bottom,#F3FAFF,#FFFFFF)' }}></div>
        <div className="footer-body">
          <div className="footer-contents links">
            <div className="footer-item">
              <div className="footer-item-title">ご利用ガイド</div>
              <div className="footer-item-body">
                <div className="footer-item-link"><a className="footer-item-link" href="/about">楽譜ストアについて</a></div>
                <div className="footer-item-link"><a className="footer-item-link" data-turbolinks="false" href="/howto">楽譜の購入方法</a></div>
                <div className="footer-item-link"><a className="footer-item-link" target="_blank" href="https://publish.piascore.com">楽譜を販売したい方</a></div>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-item-title">サポート</div>
              <div className="footer-item-body">
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://help.piascore.com/hc/ja/categories/360000082731"
                  >
                    楽譜ストアQ&amp;A
                  </a>
                </div>
                <div className="footer-item-link">
                  <a className="footer-item-link" href="/pre_contact">
                    お問い合わせ
                  </a>
                </div>
                <div className="footer-item-link">
                  <a className="footer-item-link" href="/req">
                    曲リクエスト
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-item">
              <div className="footer-item-title">当サイトについて</div>
              <div className="footer-item-body">
                <div className="footer-item-link">
                  <a className="footer-item-link" href="/company">
                    会社概要
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="http://piascore.com/ja/privacy"
                  >
                    プライバシーポリシー
                  </a>
                </div>
                <div className="footer-item-link">
                  <a className="footer-item-link" href="/term">
                    利用規約
                  </a>
                </div>
                <div className="footer-item-link">
                  <a className="footer-item-link" href="/legal">
                    特定商取引法上の記載
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-item bottom">
              <div className="footer-item-title">アプリ</div>
              <div className="footer-item-body">
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://apps.apple.com/jp/app/piascore-%E3%82%B9%E3%83%9E%E3%83%BC%E3%83%88%E3%83%87%E3%82%B8%E3%82%BF%E3%83%AB%E6%A5%BD%E8%AD%9C%E3%83%AA%E3%83%BC%E3%83%80%E3%83%BC/id406141702"
                  >
                    電子楽譜ビューア Piascore
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://apps.apple.com/jp/app/%E6%A5%BD%E5%99%A8%E3%83%81%E3%83%A5%E3%83%BC%E3%83%8A%E3%83%BC-lite-by-piascore/id635828559"
                  >
                    楽器チューナー by Piascore
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://apps.apple.com/jp/app/%E3%83%A1%E3%83%88%E3%83%AD%E3%83%8E%E3%83%BC%E3%83%A0-lite-by-piascore/id1084291731"
                  >
                    メトロノーム by Piascore
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://apps.apple.com/jp/app/18%E3%81%A4%E3%81%AE%E6%A5%BD%E5%99%A8-%E6%A5%BD%E8%AD%9C%E3%81%A8%E4%B8%80%E7%B7%92%E3%81%AB%E5%BC%BE%E3%81%91%E3%82%8B-%E3%83%94%E3%82%A2%E3%83%8E/id861163533"
                  >
                    ピアノ+ by Piascore
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-item bottom">
              <div className="footer-item-title">サービス</div>
              <div className="footer-item-body">
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://studio.piascore.com/"
                  >
                    Piascore ピアノスタジオ YOKOHAMA
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://olol.piascore.com/"
                  >
                    On Line/On Live オンラインピアノ Live シリーズ
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://lab.piascore.com/concert/"
                  >
                    Piascore オンラインコンサート情報
                  </a>
                </div>
                <div className="footer-item-link">
                  <a
                    className="footer-item-link"
                    target="_blank"
                    href="https://mmpf.yokohama/"
                  >
                    みなとみらいピアノフェスティバル
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-contents licence">
            <div className="rights_license">
              <picture>
                <source
                  srcSet="/assets/footer/jasrac-dec24d1cee38d04c74a376fdb6afed309d2c2697404f0e4fbac85be6517ef6c0.webp"
                  type="image/webp"
                />
                <source
                  srcSet="/assets/footer/jasrac-fa9de2e7bb2be615794407c736de318c00d31173a75ee4ffa03e7708bae7ec70.png"
                  type="image/png"
                />
                <img
                  className="license-logo"
                  src="/assets/footer/jasrac-fa9de2e7bb2be615794407c736de318c00d31173a75ee4ffa03e7708bae7ec70.png"
                  alt="Jasrac"
                />
              </picture>
              <div className="license_number">
                JASRAC 許諾番号
                <br />
                9013196001Y37019
              </div>
            </div>
            <div className="rights_license">
              <picture>
                <source
                  srcSet="/assets/footer/nextone-a2680eaa99d670d78cdff9a76723ae19fe7a51b65169ea1dd7bc827effac2fe1.webp"
                  type="image/webp"
                />
                <source
                  srcSet="/assets/footer/nextone-5912afc4c39c5579a537a0fce305c98e22ee9868252d5f76eb7ad52e4b13bd0b.png"
                  type="image/png"
                />
                <img
                  className="license-logo"
                  src="/assets/footer/nextone-5912afc4c39c5579a537a0fce305c98e22ee9868252d5f76eb7ad52e4b13bd0b.png"
                  alt="Nextone"
                />
              </picture>
              <div className="license_number">
                NexTone 許諾番号
                <br />
                ID000003241
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        ※ サイト内の価格はすべて税込み価格です。<br />
        © Piascore, Inc. 2010-2024 All rights reserved
      </div>
    </footer>
  );
}

export default PSStoreFooterComponent;
