import React from 'react';
//import './PSTopPageComponent.scss';
import styles from './PSStoreTopPageComponent.module.scss'

function PSScoreItemComponent({ score }) {
  return (
    <div
      className="score_contents_container slick-slide slick-current slick-active"
      data-slick-index={0}
      aria-hidden="false"
      tabIndex={0}
    >
      <a
        className="top-score-item"
        data-turbolinks="false"
        onclick="gtag('event', 'show', {'event_category': 'score','event_label': 'top_popular', 'value': '1'});"
        href="/scores/91221"
        tabIndex={0}
      >
        <img class="top-score-item-image" src={`https://image.piascore.com/store/coverimage/site_l/${score.score_id}.png`}/>
        <div className="score_list_container" style={{}}>
          <div
            className="score_list_title"
            style={{ fontWeight: "bold", color: "#0083EA" }}
          >
            {`${score.name}`} / {`${score.composer}`} 
            <div className="score_list_info_smart">
              <div
                className="score_list_inst"
                style={{ fontSize: 9, margin: "2px 0px", color: "#333333" }}
              >
                {`${score.instrument}`} / {`${score.level}`}
              </div>
              <div
                className="score_list_price"
                style={{ fontSize: 12, color: "#cf431e", margin: "3px 0 auto auto" }}
              >
                {`${score.price}`} 
              </div>
              <div>
                <span style={{ fontSize: 10, color: "#848484" }}>
                  <img
                    src={`${score.image}`}
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: "50%",
                      overflow: "hidden",
                      margin: "0 3px 0 0px",
                      border: "0.5px solid #999",
                      display: "inline"
                    }}
                  />
                  {`${score.seller}`} 
                </span>
              </div>
            </div>
          </div>
          <div className="score_list_info">
            <div className="score_list_inst" style={{ fontSize: 10, color: "#333333" }}>
              {`${score.instrument}`} / {`${score.level}`}
            </div>
            <div
              className="score_list_price"
              style={{ fontSize: 12, color: "#cf431e", margin: "auto 0 auto auto" }}
            >
              {`${score.price}`} 
            </div>
          </div>
          <div className="sp_hidden" style={{ display: "flex", margin: "2px 0 0 0px" }}>
            <div style={{ margin: "0 3px 0 0px" }}>
              <img
                src={`${score.image}`} 
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  overflow: "hidden",
                  border: "0.5px solid #999"
                }}
              />
            </div>
            <div style={{ fontSize: 10, color: "#848484" }}>{`${score.seller}`} </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default PSScoreItemComponent;


