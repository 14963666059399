import React from 'react';
//import './PSStorePersonListFromLetterComponent.scss';
import styles from './PSStoreCompanyInfoComponent.module.scss'

export default function PSStorePersonListFromLetterComponent(){

  const json = {
    query: {
      letter: "お"
    },
    people: [
      {
        person_id: 19951,
        name: "A・Ono",
        num: 2,
      },
      {
        person_id: 20045,
        name: "明美・小倉",
        num: 1,
      },
      {
        person_id: 32146,
        name: "岡田泰尚",
        num: 20,
      },
      {
        person_id: 56654,
        name: "阿部哲也",
        num: 10,
      }
    ]
  };

  return (
    <div className="list-page">
      <div className="head">
        <h1>「{json.query.letter}」から始まるアーティスト</h1>
      </div>
      <div className="body">
        <ul className={`list-group list-group-flush ${styles.item_group}`}>
          {json.people.map(person => (
            <a
              key={person.person_id}
              className={`list-group-item} d-flex justify-content-between align-items-start ${styles.item}`}
              href={`/search?p=${person.person_id}`}
            >
              {person.name}<span className="badge bg-secondary rounded-pill">{person.num}</span>
            </a>
          ))}
        </ul>
      </div>
    </div>
  );
};

