import React from 'react';

//import './PSStoreTopPageComponent.scss';
import styles from './PSStoreTopPageComponent.module.scss'

import PSStoreTopSideNavigationComponent from './PSStoreTopSideNavigationComponent';
import PSStoreTopScoreListComponent from './PSStoreTopScoreListComponent';


export default function PSStoreTopPageComponent(){

  const checkedScores = [
    { score_id: 7, name: "xx", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 2, name: "yy", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }
  ]
  const popularScores = [
    { score_id: 3, name: "zz", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }
  ]
  const newScores = [
    { score_id: 4, name: "aa", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 5, name: "bb", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 6, name: "cc", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 7, name: "aa", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 8, name: "bb", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 9, name: "cc", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 7, name: "aa", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 8, name: "bb", composer: "abe", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }, { score_id: 9, name: "cc", composer: "okada", instrument: "ピアノ", level: "超上級", price: "¥14,520", seller: "Dさん", image: "https://image.piascore.com/company/icons/1042_400_400.jpg" }
  ]

  return (
    <div className="index_wrapper" style={{ overflow: 'hidden' }}>
      <div className="category_container">
        <PSStoreTopSideNavigationComponent />
      </div>
      <div className="scores_container">
        <div className="scoreblock_wrapper">
          <div className="top-scoreblock-row-margin" />
          <PSStoreTopScoreListComponent headline="最近チェックした楽譜" scores={checkedScores} />
          <PSStoreTopScoreListComponent headline="人気の楽譜" scores={popularScores} />
          <PSStoreTopScoreListComponent headline="新着の楽譜" scores={newScores} more="/scores" />
          <PSStoreTopScoreListComponent headline="J-POP" scores={newScores} more="/search?g=010" />
        </div>
      </div>
    </div>

  )
};





