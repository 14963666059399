//
const simple_json_instruments = [
  {
    "instrument_id": "10",
    "name": "ピアノ（ソロ）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "20",
    "name": "ピアノ（弾き語り）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "25",
    "name": "ピアノ（伴奏）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "30",
    "name": "ピアノ（連弾）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "35",
    "name": "ピアノ（1台6手）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "40",
    "name": "ピアノ（2台4手）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "45",
    "name": "ピアノ（2台8手）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "50",
    "name": "電子オルガン（ソロ）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "52",
    "name": "電子オルガン（アンサンブル）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "54",
    "name": "電子オルガン（弾き語り）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "57",
    "name": "オルガン",
    "emoji": "🎹"
  },
  {
    "instrument_id": "70",
    "name": "チェンバロ",
    "emoji": "🎹"
  },
  {
    "instrument_id": "80",
    "name": "アコーディオン",
    "emoji": "🪗"
  },
  {
    "instrument_id": "83",
    "name": "鍵盤ハーモニカ",
    "emoji": "🎹"
  },
  {
    "instrument_id": "84",
    "name": "トイピアノ / チェレスタ",
    "emoji": "🎹"
  },
  {
    "instrument_id": "100",
    "name": "ギター（弾き語り）",
    "emoji": "🎸"
  },
  {
    "instrument_id": "110",
    "name": "ギター（ソロ）",
    "emoji": "🎸"
  },
  {
    "instrument_id": "130",
    "name": "メロディ",
    "emoji": null
  },
  {
    "instrument_id": "140",
    "name": "ギター（デュオ）",
    "emoji": "🎸"
  },
  {
    "instrument_id": "147",
    "name": "ギター（重奏）",
    "emoji": "🎸"
  },
  {
    "instrument_id": "150",
    "name": "バンドスコア",
    "emoji": "🎸"
  },
  {
    "instrument_id": "160",
    "name": "ギター（室内楽）",
    "emoji": "🎸"
  },
  {
    "instrument_id": "170",
    "name": "ウクレレ（ソロ）",
    "emoji": null
  },
  {
    "instrument_id": "173",
    "name": "ウクレレ（弾き語り）",
    "emoji": null
  },
  {
    "instrument_id": "176",
    "name": "ウクレレ（アンサンブル）",
    "emoji": null
  },
  {
    "instrument_id": "180",
    "name": "エレキギター",
    "emoji": "🎸"
  },
  {
    "instrument_id": "183",
    "name": "エレキベース",
    "emoji": "🎸"
  },
  {
    "instrument_id": "190",
    "name": "マンドリン",
    "emoji": "🪕"
  },
  {
    "instrument_id": "205",
    "name": "ヴァイオリン",
    "emoji": "🎻"
  },
  {
    "instrument_id": "210",
    "name": "ヴィオラ",
    "emoji": "🎻"
  },
  {
    "instrument_id": "215",
    "name": "チェロ",
    "emoji": "🎻"
  },
  {
    "instrument_id": "220",
    "name": "コントラバス",
    "emoji": "🎻"
  },
  {
    "instrument_id": "225",
    "name": "ハープ",
    "emoji": null
  },
  {
    "instrument_id": "320",
    "name": "アルトサックス",
    "emoji": "🎷"
  },
  {
    "instrument_id": "322",
    "name": "テナーサックス",
    "emoji": "🎷"
  },
  {
    "instrument_id": "324",
    "name": "バリトンサックス",
    "emoji": "🎷"
  },
  {
    "instrument_id": "326",
    "name": "ソプラノサックス",
    "emoji": "🎷"
  },
  {
    "instrument_id": "335",
    "name": "ホルン",
    "emoji": "🎺"
  },
  {
    "instrument_id": "340",
    "name": "トランペット",
    "emoji": "🎺"
  },
  {
    "instrument_id": "342",
    "name": "トロンボーン",
    "emoji": "🎺"
  },
  {
    "instrument_id": "344",
    "name": "チューバ",
    "emoji": "🎺"
  },
  {
    "instrument_id": "348",
    "name": "ユーフォニアム",
    "emoji": "🎺"
  },
  {
    "instrument_id": "350",
    "name": "フルート",
    "emoji": "🪈"
  },
  {
    "instrument_id": "353",
    "name": "ピッコロ",
    "emoji": "🪈"
  },
  {
    "instrument_id": "356",
    "name": "オーボエ",
    "emoji": "🪈"
  },
  {
    "instrument_id": "359",
    "name": "クラリネット",
    "emoji": "🪈"
  },
  {
    "instrument_id": "362",
    "name": "ファゴット",
    "emoji": "🪈"
  },
  {
    "instrument_id": "405",
    "name": "オカリナ",
    "emoji": null
  },
  {
    "instrument_id": "410",
    "name": "リコーダー",
    "emoji": "🪈"
  },
  {
    "instrument_id": "415",
    "name": "ハーモニカ",
    "emoji": null
  },
  {
    "instrument_id": "420",
    "name": "三味線",
    "emoji": null
  },
  {
    "instrument_id": "425",
    "name": "二胡",
    "emoji": null
  },
  {
    "instrument_id": "427",
    "name": "三線",
    "emoji": null
  },
  {
    "instrument_id": "428",
    "name": "サンレレ",
    "emoji": null
  },
  {
    "instrument_id": "430",
    "name": "大正琴",
    "emoji": null
  },
  {
    "instrument_id": "435",
    "name": "箏",
    "emoji": null
  },
  {
    "instrument_id": "440",
    "name": "篠笛",
    "emoji": null
  },
  {
    "instrument_id": "443",
    "name": "笙",
    "emoji": null
  },
  {
    "instrument_id": "445",
    "name": "尺八",
    "emoji": null
  },
  {
    "instrument_id": "450",
    "name": "琵琶",
    "emoji": null
  },
  {
    "instrument_id": "520",
    "name": "アンサンブル（ピアノ＋他楽器）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "530",
    "name": "アンサンブル（ピアノ＋歌）",
    "emoji": "🎹"
  },
  {
    "instrument_id": "550",
    "name": "アンサンブル（パート）",
    "emoji": null
  },
  {
    "instrument_id": "570",
    "name": "アンサンブル（金管）",
    "emoji": "🎺"
  },
  {
    "instrument_id": "572",
    "name": "アンサンブル（木管）",
    "emoji": "🪈"
  },
  {
    "instrument_id": "573",
    "name": "アンサンブル（弦楽）",
    "emoji": "🎻"
  },
  {
    "instrument_id": "575",
    "name": "アンサンブル（リコーダー）",
    "emoji": "🪈"
  },
  {
    "instrument_id": "577",
    "name": "アンサンブル（打楽器）",
    "emoji": "🥁"
  },
  {
    "instrument_id": "580",
    "name": "オーケストラ",
    "emoji": null
  },
  {
    "instrument_id": "586",
    "name": "吹奏楽（パート）",
    "emoji": "🎺"
  },
  {
    "instrument_id": "588",
    "name": "吹奏楽（全パート）",
    "emoji": "🎺"
  },
  {
    "instrument_id": "590",
    "name": "吹奏楽（フルスコア）",
    "emoji": "🎺"
  },
  {
    "instrument_id": "595",
    "name": "弦楽四重奏",
    "emoji": "🎻"
  },
  {
    "instrument_id": "597",
    "name": "ビッグバンド",
    "emoji": null
  },
  {
    "instrument_id": "605",
    "name": "アカペラ",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "610",
    "name": "ゴスペル",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "615",
    "name": "合唱",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "618",
    "name": "合唱（混声3部）",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "619",
    "name": "合唱（混声4部）",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "633",
    "name": "合唱（同声3部）",
    "emoji": "🗣️"
  },
  {
    "instrument_id": "705",
    "name": "ドラム",
    "emoji": "🥁"
  },
  {
    "instrument_id": "740",
    "name": "マリンバ",
    "emoji": null
  },
  {
    "instrument_id": "745",
    "name": "シロフォン   ",
    "emoji": null
  },
  {
    "instrument_id": "750",
    "name": "ビブラフォン",
    "emoji": null
  },
  {
    "instrument_id": "755",
    "name": "グロッケンシュピール",
    "emoji": null
  },
  {
    "instrument_id": "910",
    "name": "全て",
    "emoji": null
  },
  {
    "instrument_id": "915",
    "name": "ハンドベル / ミュージックベル",
    "emoji": "🔔"
  },
  {
    "instrument_id": "920",
    "name": "ハンドチャイム",
    "emoji": null
  },
  {
    "instrument_id": "925",
    "name": "カリンバ",
    "emoji": null
  },
  {
    "instrument_id": "99910",
    "name": "その他",
    "emoji": null
  },
  {
    "instrument_id": "99920",
    "name": "不明",
    "emoji": null
  }
];

 // レコメンドキーワードを設定
 const recommend_keywords = [
  { p: "n=Bling-Bang-Bang-Born", k: "Bling-Bang-Bang-Born" },
  { p: "n=ケセラセラ", k: "ケセラセラ" },
  { p: "n=コウを追いかけて", k: "コウを追いかけて" },
  { p: "n=タイムパラドックス", k: "タイムパラドックス" },
  { p: "n=晩餐歌", k: "晩餐歌" }
];

const PSStoreMiscData = {
  simple_json_instruments,
  recommend_keywords
};

export default PSStoreMiscData;
