import React from 'react';
import './PSStorePersonsComponent.scss';

const json = [
  [
    ["あ", "い", "う", "え", "お"],
    ["か", "き", "く", "け", "こ"],
    ["が", "ぎ", "ぐ", "げ", "ご"],
    ["さ", "し", "す", "せ", "そ"],
    ["ざ", "じ", "ず", "ぜ", "ぞ"],
    ["た", "ち", "つ", "て", "と"],
    ["だ", "ぢ", "づ", "で", "ど"],
    ["な", "に", "ぬ", "ね", "の"]
  ],
  [
    ["は", "ひ", "ふ", "へ", "ほ"],
    ["ば", "び", "ぶ", "べ", "ぼ"],
    ["ぱ", "ぴ", "ぷ", "ぺ", "ぽ"],
    ["ま", "み", "む", "め", "も"],
    ["や", "ゆ", "よ"],
    ["ら", "り", "る", "れ", "ろ"],
    ["わ", "を", "ん", "ゔ"]
  ],
  [
    ["A", "B", "C", "D", "E"],
    ["F", "G", "H", "I", "J"],
    ["K", "L", "M", "N", "O"],
    ["P", "Q", "R", "S", "T"],
    ["U", "V", "W", "X", "Y"], 
    ["Z"]
  ],
  [
    ["0", "1", "2", "3", "4"],
    ["5", "6", "7", "8", "9"]
  ]
];


export default function PSStorePersonsComponent(){
  return (
  
    <div className="list-page">
      <div className="head">
        <h1>全てのアーティスト</h1>
      </div>
      <div className="body" style={{ padding: '0 0 0 20px' }}>
        <div style={{ fontSize: '16px' }}>頭文字を選択してください</div>
      </div>
      
      <div style={{ margin: '20px 0 0 0' }}>
        {json.map((group, index) => (
          <div key={index} className="letter_block_box">
            {group.map((row, rowIndex) => (
              <React.Fragment key={rowIndex}>
                {row.map((letter, letterIndex) => (
                  <div key={letterIndex} className="letter_block">
                    <a style={{ fontSize: '16px', color: '#0083EA' }} href={encodeURI(`/persons/letter/${letter}`)}>
                      {letter}
                    </a>
                  </div>
                ))}
                <div className="letter_border" style={{ borderBottom: '1px solid #DDD', clear: 'both' }}></div>
              </React.Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

