import React from 'react';
import './PSStoreGenreListComponent.scss'; // ここにCSSファイルをインポート

export default function PSStoreGenreListComponent() {
  const json = [{
    category: {
        regions: {
            ja: {
                name: "J-Pop"
            }
        }
    },
    genres: [{
            genre_code: "010",
            num: 114590,
            regions: {
                ja: {
                    name: "全て"
                }
            }
        },
        {
            genre_code: "010010",
            num: 50052,
            regions: {
                ja: {
                    name: "ポップス"
                }
            }
        },
    ]
},
{
    category: {
        regions: {
            ja: {
                name: "ポップス"
            }
        }
    },
    instruments: [{
            genre_code: "020",
            num: 3155,
            regions: {
                ja: {
                    name: "全て"
                }
            }
        },
        {
            genre_code: "020060",
            num: 185,
            regions: {
                ja: {
                    name: "K-Pop・アジア"
                }
            }
        },
    ]
  }
  ]


  return (
    <div className="container">
      <div className="list-page">
        <div className="head">
          <h1>全てのジャンル</h1>
        </div>
        <div className="body">
          {json.map(category => (
            <ul className="list-group list-group-flush item-group" key={category.category.regions.ja.name}>
              <li className="list-group-item d-flex justify-content-between title">
                {category.category.regions.ja.name.toUpperCase()}
              </li>
              {category.genres ? category.genres.map(genre => (
                <a
                  className="list-group-item d-flex justify-content-between align-items-start item"
                  href={`/search?g=${genre.genre_code}`}
                  key={genre.genre_code}
                  style={{ display: "list-item" }}
                >
                  {genre.regions.ja.name}
                  <span className="badge bg-secondary rounded-pill">{genre.num}</span>
                </a>
              )) : category.instruments.map(instrument => (
                <a
                  className="list-group-item d-flex justify-content-between align-items-start item"
                  href={`/search?g=${instrument.genre_code}`}
                  key={instrument.genre_code}
                  style={{ display: "list-item" }}
                >
                  {instrument.regions.ja.name}
                  <span className="badge bg-secondary rounded-pill">{instrument.num}</span>
                </a>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
}
