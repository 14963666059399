import React from 'react';

//import './PSStoreTopPageComponent.scss';
import styles from './PSStoreTopPageComponent.module.scss'

export default function PSStoreTopSideNavigationComponent() {
  const json = {
    instruments: [
      { instrument_id: 10, name: "ピアノ（ソロ）" },
    ],
    genres: [
      { genre_id: 100, name: "クラシック" },
    ],
    persons: [
    ],
    publishers: [
    ]
  };

  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "@media screen and (max-width: 600px){.smart_contents_container{height:26px;margin:12px 0 0 0;padding:5px 10px;width:100%;font-size:12px;font-weight:bold;color:#0065b4;background:#abdbff}.contents_wrapper{color:#4A4A4A;margin:0 0px}.find-title-smart{padding-left:10px;width:100%;line-height:50px;font-size:16px;border-top:1px solid #dbdbdb;border-bottom:1px solid #dbdbdb;margin:-1px 0 0 -1px;display:block}.find-title{display:none}.find-element-outer{display:none}}@media screen and (min-width: 600px){.smart_contents_container{display:none}.contents_wrapper{color:#0083EA;margin:0 5px}.find-title{font-size:16px;font-weight:bold;padding:2px 0;margin:3px 0}.find-title .find-title-span{padding:0 0 0 3px}.find-title-smart{display:none}}.find-title .find-title-span{font-size:10px}.find-element-outer{padding:0 13px 13px 0}.find-element-outer .find-element{margin:5px 0;font-size:14px;display:block;color:#0083EA}"
        }}
      />
      <div className="smart_contents_container">楽譜をさがす</div>
      <div
        className="contents_wrapper"
        style={{ borderRight: "1px solid #E0E0E0" }}
      >
        <div>
          <a className="find-title-smart" href="/instruments">
            <i className="fa fa-bell" />
            <span className="mgr-10" style={{ padding: "0 0 0 5px" }}>
              楽器
            </span>
            <span className="find-title-span">からさがす</span>
          </a>
        </div>
        <div>
          <div className="find-title">
            楽器<span className="find-title-span">から</span>
          </div>
          <div style={{ margin: "0 0 0 12px" }}>
            <div className="find-element-outer">

              {json.instruments.map(instrument => (
                <a
                  key={instrument.instrument_id}
                  className="find-element"
                  href={`/search?i=${instrument.instrument_id}`}
                >
                  {instrument.name}
                </a>
              ))}
              <div style={{ textAlign: "right" }}>
                <a href="/instruments">
                  <span style={{ fontSize: 11 }}>もっとみる</span>
                  <span className="fa fa-arrow-right" style={{ paddingLeft: 3 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a className="find-title-smart" href="/genres">
            <i className="fa fa-music" />
            <span className="mgr-10" style={{ padding: "0 0 0 5px" }}>
              ジャンル
            </span>
            <span className="find-title-span">からさがす</span>
          </a>
        </div>
        <div>
          <div className="find-title">
            ジャンル<span className="find-title-span">から</span>
          </div>
          <div style={{ margin: "0 0 0 12px" }}>
            <div className="find-element-outer">
              {json.genres.map(genre => (
                <a
                  key={genre.genre_id}
                  className="find-element"
                  href={`/search?g=${genre.genre_id}`}
                >
                  {genre.name}
                </a>
              ))}
              <div style={{ textAlign: "right" }}>
                <a href="/genres">
                  <span style={{ fontSize: 11 }}>もっとみる</span>
                  <span className="fa fa-arrow-right" style={{ paddingLeft: 3 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a className="find-title-smart" href="/persons">
            <i className="fa fa-user" />
            <span className="mgr-10" style={{ padding: "0 0 0 5px" }}>
              アーティスト
            </span>
            <span className="find-title-span">からさがす</span>
          </a>
        </div>
        <div>
          <div className="find-title">
            アーティスト<span className="find-title-span">から</span>
          </div>
          <div style={{ margin: "0 0 0 12px" }}>
            <div className="find-element-outer">
              {json.persons.map(person => (
                <a
                  key={person.person_id} // 仮のキー
                  className="find-element"
                  href={`/search?p=${person.person_id}`} // 仮のパス
                >
                  {person.name}
                </a>
              ))}
              <div style={{ textAlign: "right" }}>
                <a href="/persons">
                  <span style={{ fontSize: 11 }}>もっとみる</span>
                  <span className="fa fa-arrow-right" style={{ paddingLeft: 3 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <a className="find-title-smart" href="/publishers">
            <span className="fas fa-building" />
            <span className="mgr-10" style={{ padding: "0 0 0 5px" }}>
              出版社
            </span>
            <span className="find-title-span">からさがす</span>
          </a>
        </div>
        <div>
          <div className="find-title">
            出版社<span className="find-title-span">から</span>
          </div>
          <div style={{ margin: "0 0 0 12px" }}>
            <div className="find-element-outer">
              {json.publishers.map(publisher => (
                <a
                  key={publisher.publisher_id} // 仮のキー
                  className="find-element"
                  href={`/search?pub=${publisher.publisher_id}`} // 仮のパス
                >
                  {publisher.name}
                </a>
              ))}
              <div style={{ textAlign: "right" }}>
                <a href="/publishers">
                  <span style={{ fontSize: 11 }}>もっとみる</span>
                  <span className="fa fa-arrow-right" style={{ paddingLeft: 3 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
