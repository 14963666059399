import React, { useState } from 'react';

//import './PSStoreTopPageComponent.scss';
import styles from './PSStoreTopPageComponent.module.scss'

import PSStoreScoreItemComponent from './PSStoreScoreItemComponent';

export default function PSStoreTopScoreListComponent({ headline, scores, more = null }) {
  const [isHovering, setIsHovering] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false); // ドラッグ中かどうか
  const [startX, setStartX] = useState(0); // ドラッグ開始時のX座標
  const [offset, setOffset] = useState(0); // ドラッグによるオフセット

  // マウスが要素上で押された時の処理
  const handleMouseDown = (e) => {
    e.preventDefault(); // ブラウザのデフォルト動作を防ぐ
    setStartX(e.clientX); // ドラッグ開始時のマウスのX座標を保存
    // ここではまだ setIsDragging(true) を呼ばない
  };

  // マウスが要素上で移動した時の処理
  const handleMouseMove = (e) => {
    // マウスが押下されているか確認
    if (e.buttons === 1) {
      e.preventDefault();
      const currentX = e.clientX;
      const newOffset = currentX - startX;

      // 実際にマウスが動いたか確認（例：5ピクセル以上）
      if (!isDragging && Math.abs(newOffset) > 5) {
        setIsDragging(true); // ドラッグ開始とみなす
      }

      // ドラッグ状態であればオフセットを更新
      if (isDragging) {
        setOffset(newOffset);
      }
    }
  };

  // マウスが要素上で離された時、または要素の範囲外に出た時の処理
  const handleMouseLeaveOrUp = (e) => {
    e.preventDefault(); // ブラウザのデフォルト動作を防ぐ
    if (isDragging) {
      // 共通の処理を行うため、handleMouseUp のロジックをここに統合
      handleMouseUp(e);
      e.preventDefault();
    }
  };

  const handleMouseUp = (e) => {
    // この関数が直接イベントリスナーから呼び出される場合も考慮して、
    // preventDefault の呼び出しを保持
    if (e) e.preventDefault(); // ブラウザのデフォルト動作を防ぐ

    if (isDragging) {
      setIsDragging(false);

      // スワイプした距離（offset）を要素1つ分の幅（188px）で割り、
      // スライドを移動させるべき要素数を計算
      const moveCount = Math.round(Math.abs(offset) / 188);

      // offsetの符号に応じて、スライドのインデックスを更新
      if (offset < 0) { // 左にスワイプした場合
        setSlideIndex(Math.min(slideIndex + moveCount, scores.length - 1));
      } else if (offset > 0) { // 右にスワイプした場合
        setSlideIndex(Math.max(slideIndex - moveCount, 0));
      }

      setOffset(0); // オフセットをリセット
    }
  };



  // 左ボタンクリックでスライドを左に移動
  const handleLeftClick = () => {
    setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 0);
  };

  // 右ボタンクリックでスライドを右に移動
  const handleRightClick = () => {
    setSlideIndex(slideIndex < scores.length - 1 ? slideIndex + 1 : slideIndex);
  };

  const slideStyle = {
    transform: `translate3d(${(-slideIndex * 188) + offset}px, 0, 0)`,
    transition: isDragging ? 'none' : 'transform 0.25s ease',
  };

  return (
    <div className="top-scoreblock-row-margin">
      <div className="index_scores_wrapper">
        <div className="index_contents_wrapper">
          <div className="index_contents_container">
            <div className="index_contents_title">
              <div className="index_title_container" style={{ margin: "auto" }}>
                <div className="index_contents_text">
                  {headline}
                </div>
                {
                  more !== null && (
                    <div className="scoreblock_more_link">
                      <a href={more}>
                        <span className="more_browse" style={{ fontSize: 11 }}>
                          もっとみる
                        </span>
                        <span
                          className="fa fa-arrow-right more_browse_arrow"
                          style={{ paddingLeft: 3 }}
                        />
                      </a>
                    </div>
                  )}
                <div style={{ clear: "both" }} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="scoreblock-box sp_hidden"
          style={{ position: "relative", overflow: "hidden" }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseLeaveOrUp}
          onMouseLeave={handleMouseLeaveOrUp} // 要素外にマウスが移動した際にもドラッグを終了させる
        >
          <button
            className="cursor-btn cursor-btn-left"
            onClick={handleLeftClick} // 左ボタンクリックイベントを追加
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgb(51, 51, 51)",
              display: isHovering ? "inline-block" : "none",
            }}
          >
            <span
              aria-hidden="true"
              className="fa fa-chevron-left"
              style={{ fontSize: 16 }}
            />
          </button>
          <button
            className="cursor-btn cursor-btn-right"
            onClick={handleRightClick} // 右ボタンクリックイベントを追加
            style={{
              backgroundColor: "rgb(255, 255, 255)",
              color: "rgb(51, 51, 51)",
              display: isHovering ? "inline-block" : "none",
            }}
          >
            <span
              aria-hidden="true"
              className="fa fa-chevron-right"
              style={{ fontSize: 16 }}
            />
          </button>
          <div
            className="scoreblock-carousel slick-initialized slick-slider"
            style={{ display: "flex" }}
          >
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{ ...slideStyle, display: "flex", transition: "transform 0.25s ease" }} // スライドスタイルを適用
              >
                {
                  scores.map((s, index) => (
                    <PSStoreScoreItemComponent key={index} score={s} />
                  ))
                }
                {
                  more !== null && (
                    <div
                      className="pc_nav_left slick-slide slick-active"
                      style={{
                        background: "linear-gradient(to bottom,#F0F8FF,#DDEFFD)",
                        display: "flex",
                        position: "relative"
                      }}
                      data-slick-index={10}
                      aria-hidden="false"
                      tabIndex={0}
                    >
                      <div
                        className="right_more"
                        style={{ padding: "0 8px 0 0", height: 256, width: 182 }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            position: "absolute",
                            bottom: "45%",
                            left: "0%",
                            right: "0%"
                          }}
                        >
                          <a style={{ outline: "none" }} href={more} tabIndex={0}>
                            <span style={{ fontSize: 17, verticalAlign: "middle" }}>
                              もっとみる
                            </span>
                            <span
                              className="fa fa-arrow-right"
                              style={{ fontSize: 17, verticalAlign: "top", margin: 2 }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
