import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

//import './PSStoreCompanyInfoComponent.scss';
import styles from './PSStoreCompanyInfoComponent.module.scss'

const PSStoreCompanyInfoComponent = () => {
  return (
    <div className={styles.content_980px} style={{ margin: "0 auto", padding: 40 }}>
      <h1>会社概要</h1>

      <div>
        <div className={styles.term_headline}>社名</div>
        <div className={styles.term_body}>Piascore 株式会社</div>
      </div>

      <div>
        <div className={styles.term_headline}>本社</div>
        <div className={styles.term_body} dangerouslySetInnerHTML={{ __html: "〒220-0011<br>神奈川県横浜市西区高島2-11-2<br>スカイメナー横浜 921号室" }} />
      </div>

      <div>
        <div className={styles.term_headline}>代表者</div>
        <div className={styles.term_body}>代表取締役社長 小池宏幸</div>
      </div>

      <div>
        <div className={styles.term_headline}>設立</div>
        <div className={styles.term_body}>2010年5月6日</div>
      </div>

      <div>
        <div className={styles.term_headline}>事業内容</div>
        <div className={styles.term_body}>
          <ul>
            <li>電子楽譜 <a href="https://piascore.com/ja" target="_blank" rel="noopener noreferrer">Piascore</a></li>
            <li>アプリ開発事業</li>
            <li>音楽教育事業</li>
          </ul>
        </div>
      </div>

      <div>
        <div className={styles.term_headline}>ホームページ</div>
        <div className={styles.term_body}>
          <a href="https://piascore.com/ja" target="_blank" rel="noopener noreferrer">https://piascore.com</a>
        </div>
      </div>

      <div className={styles.home_return}>
        <a href="/"><FontAwesomeIcon icon={faHome} />ホームに戻る</a>
      </div>
    </div>
  );
}

export default PSStoreCompanyInfoComponent;
