import React from 'react';
import styles from './PSStorePreContactComponent.module.scss'

export default function PSStorePreContactComponent() {
  return (
    <div className={styles.contact_form_wrapper} style={{ margin: "0 auto" }}>
      <div className={styles.webstore_form_frame} style={{ padding: "15px 0" }}>
        <div
          style={{
            color: "#333333",
            borderBottom: "1px solid #D8D8D8",
            fontSize: "22px",
            textAlign: "center",
            fontWeight: "bold",
            margin: "0 0px",
            padding: "30px 0 40px",
            lineHeight: "18px"
          }}
        >
          お問い合わせ
        </div>
        <div className={styles.contact_comment}>
          よくお問い合わせいただく質問と回答をまとめました
        </div>
        <div className={styles.contact_qa_container}>
          <div className={styles.qa}>
            <input
              defaultChecked="true"
              id={styles.contact_conttab1}
              name="tabs"
              type="radio"
            />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab1}>
              <div />
              <span
                className="fas fa-user-edit"
                style={{ marginRight: 10, fontSize: 20 }}
              />{" "}
              会員登録
            </label>
            <input id={styles.contact_conttab2} name="tabs" type="radio" />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab2}>
              <div>
                <span
                  className="fas fa-sign-in-alt"
                  style={{ marginRight: 10, fontSize: 20 }}
                />{" "}
                ログイン
              </div>
            </label>
            <input id={styles.contact_conttab3} name="tabs" type="radio" />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab3}>
              <div>
                <span
                  className="fas fa-shopping-cart"
                  style={{ marginRight: 10, fontSize: 20 }}
                />{" "}
                購入
              </div>
            </label>
            <input id={styles.contact_conttab4} name="tabs" type="radio" />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab4}>
              <div>
                <span
                  className="fas fa-file-download"
                  style={{ marginRight: 10, fontSize: 20 }}
                />{" "}
                ダウンロード
              </div>
            </label>
            <input id={styles.contact_conttab5} name="tabs" type="radio" />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab5}>
              <div>
                <span
                  className="far fa-file-pdf"
                  style={{ marginRight: 10, fontSize: 20 }}
                />{" "}
                楽譜の閲覧
              </div>
            </label>
            <input id={styles.contact_conttab6} name="tabs" type="radio" />
            <label className={styles.contact_tabitem} htmlFor={styles.contact_conttab6}>
              <div>
                <span
                  className="fas fa-print"
                  style={{ marginRight: 10, fontSize: 20 }}
                />{" "}
                印刷
              </div>
            </label>
            <div id={styles.cp_content1}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour011" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour011">
                      {" "}
                      仮登録のメールが届きません
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>登録されたメールアドレスに間違いはないですか？</li>
                          <li>
                            迷惑メールフォルダやゴミ箱に自動振り分けされていませんか？
                          </li>
                          <li>
                            迷惑メール設定によって受信が拒否されていませんか？
                          </li>
                        </ul>
                        <br />
                        詳しくは{" "}
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360038202672-%E4%BC%9A%E5%93%A1%E7%99%BB%E9%8C%B2%E5%BE%8C-%E4%BB%AE%E7%99%BB%E9%8C%B2%E3%81%AE%E3%83%A1%E3%83%BC%E3%83%AB%E3%81%8C%E5%B1%8A%E3%81%8D%E3%81%BE%E3%81%9B%E3%82%93"
                          target="_blank"
                        >
                          会員登録後、仮登録のメールが届きません
                        </a>{" "}
                        をご覧ください
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour012" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour012">
                      {" "}
                      パスワードがうまく設定できません
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        {" "}
                        パスワードは
                        アルファベット大文字、小文字、数字を各1文字以上混在させ、6文字以上20文字以下で設定していますか?
                        <div className={styles.pass_table}>
                          <table>
                            <thead>
                              <tr>
                                <th scope="col" style={{ width: 120 }}>
                                  パスワード
                                </th>
                                <th scope="col" style={{ width: 60 }}>
                                  判定
                                </th>
                                <th scope="col"> 理由</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Piano123</th>
                                <td className={styles.txt} data-label="判定">
                                  {" "}
                                  OK
                                </td>
                                <td className={styles.reason} data-label="理由" />
                              </tr>
                              <tr>
                                <th>123Music</th>
                                <td className={styles.txt} data-label="判定">
                                  {" "}
                                  OK
                                </td>
                                <td className={styles.reason} data-label="理由" />
                              </tr>
                              <tr>
                                <th>piano123</th>
                                <td className={styles.txt} data-label="判定">
                                  NG{" "}
                                </td>
                                <td className={styles.reason} data-label="理由">
                                  {" "}
                                  アルファベット大文字が入っていない
                                </td>
                              </tr>
                              <tr>
                                <th>Musicsheet</th>
                                <td className={styles.txt} data-label="判定">
                                  NG{" "}
                                </td>
                                <td className={styles.reason} data-label="理由">
                                  {" "}
                                  数字が入っていない
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour013" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour013">
                      {" "}
                      登録を完了がクリックできません
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        {" "}
                        OS標準のメールアプリでない場合、URLをクリックできない場合があります。
                        <br />
                        <br />
                        標準のアプリをお使いいただくか、PCへ転送してご覧ください
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={styles.cp_content2}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour021" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour021">
                      {" "}
                      正しいパスワードを入れてもエラーになってしまいます
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>パスワードは全て半角で入力していますか</li>
                          <li>
                            パスワードは大文字・小文字を区別しますのでご注意ください
                          </li>
                          <ul>
                            <li>「A（大文字）」「a（小文字）」</li>
                          </ul>
                          <li>
                            文字の最初や最後、文字の間にスペースが入力されていませんか？
                          </li>
                          <ul>
                            <li>
                              コピー＆ペーストをすると、文字の前後にスペースが入る場合があります
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour022" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour022">
                      「無効なアドレスです」と表示されます
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>正しいアドレスで入力していますか？</li>
                          <li>登録は完了していますか？</li>
                          <ul>
                            <li>
                              「Piascore仮登録のお知らせ」という題名のメールをお送りしていますので、メール中のリンクをクリックいただき、登録を完了させてください
                            </li>
                          </ul>
                        </ul>
                        詳しくは{" "}
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360038584391-%E3%83%AD%E3%82%B0%E3%82%A4%E3%83%B3%E6%99%82%E3%81%AB-%E7%84%A1%E5%8A%B9%E3%81%AA%E3%82%A2%E3%83%89%E3%83%AC%E3%82%B9%E3%81%A7%E3%81%99-%E3%81%A8%E8%A1%A8%E7%A4%BA%E3%81%95%E3%82%8C%E3%81%BE%E3%81%99"
                          target="_blank"
                        >
                          ログイン時に「無効なアドレスです」と表示されます
                        </a>{" "}
                        をご覧ください
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour023" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour023">
                      ログインのパスワードを忘れてしまいました
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        ログインのパスワードを忘れてしまった場合は、下記よりリセットして、新しく設定してください
                        <br />
                        <br />
                        <a href="/request_password_reset" target="_blank">
                          パスワードのリセット
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={styles.cp_content3}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour030" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour030">
                      {" "}
                      銀行振り込みで購入したい
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li style={{ listStyleType: "none" }}>
                            ログイン後、
                            <a
                              href="https://store.piascore.com/contact?opinion_type=pay_by_bank"
                              target="_blank"
                              rel="noopener"
                            >
                              お問合せフォーム
                            </a>
                            よりお申し込みください。
                          </li>
                          <ul>
                            <li style={{ listStyleType: "none" }}>
                              ➡{" "}
                              <a
                                href="https://store.piascore.com/contact?opinion_type=pay_by_bank"
                                target="_blank"
                                rel="noopener"
                              >
                                お問合せフォーム
                              </a>
                            </li>
                            <li style={{ listStyleType: "none" }}>
                              ※ 会員登録が必要です
                            </li>
                          </ul>
                          <li style={{ listStyleType: "none" }}>
                            {" "}
                            購入希望楽譜の下記情報が必要になります。ご確認の上、お申込みください。
                          </li>
                          <ul>
                            <li style={{ listStyleType: "none" }}>
                              1. 楽譜名：
                              <br />
                              2. 楽譜ID：
                            </li>
                          </ul>
                          <li style={{ listStyleType: "none" }}>
                            楽譜IDは、楽譜名の右横に記載されています。
                          </li>
                          <div>
                            <img
                              className={styles.scoreid_bank}
                              src="/assets/scoreid_bank-430eef9b70169d59a176b8d97293323fe4e4a4ea61a3f0a68903bf4c4980c790.png"
                              alt="Scoreid bank"
                            />
                          </div>
                        </ul>
                        <div className={styles.contact_actab_content_text}>
                          <br />
                          詳しくは、下記をご覧ください.
                          <br />
                          <br />
                          <span>➡ </span>
                          <a
                            href="https://help.piascore.com/hc/ja/articles/4407712683289"
                            target="_brank"
                          >
                            銀行振り込みで購入したい
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour031" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour031">
                      {" "}
                      「Only available in Japan」と表示されて購入ができません
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>海外からのご購入の場合</li>
                          <ul>
                            <li>
                              申し訳ございませんが、海外からは自作曲やクラシック音楽など、著作権管理団体の管理外の楽曲のみご購入いただけるようになっています。
                            </li>
                          </ul>
                          <br />
                          <li> 国内からのご購入の場合</li>
                          <ul>
                            <li>
                              お客様の接続元IPアドレスが海外のものと判断されています。違うプロバイダやネットが使えるカフェなど、異なるネットワーク環境上からページを閲覧いただけますようお願いします。
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour032" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour032">
                      {" "}
                      欲しい楽譜に購入ボタンが表示されていません{" "}
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>海外からのご購入の場合</li>
                          <ul>
                            <li>
                              申し訳ございませんが、海外からは自作曲やクラシック音楽など、著作権管理団体の管理外の楽曲のみご購入いただけるようになっています。
                            </li>
                          </ul>
                          <br />
                          <li> スマートフォンからの場合</li>
                          <ul>
                            <li>
                              {" "}
                              一部機種で表示されない場合があります。画面を右側までスクロールしていただくか、全体を縮小して見ていただけますようお願いいたします。
                            </li>
                          </ul>
                          <br />
                          <li>
                            お客様の居住地が海外判定になってしまっている場合
                            <ul>
                              <li>
                                お客様の接続元IPアドレスが海外のものと判断されています。違うプロバイダやネットが使えるカフェなど、異なるネットワーク環境上からページを閲覧いただけますようお願いします。
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour033" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour033">
                      {" "}
                      クレジットカード以外で決済できますか?{" "}
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>銀行振込によるご購入に対応しております</li>
                          <ul>
                            <li style={{ listStyleType: "none" }}>
                              ログイン後、
                              <a
                                href="https://store.piascore.com/contact?opinion_type=pay_by_bank"
                                target="_blank"
                                rel="noopener"
                              >
                                お問合せフォーム
                              </a>
                              よりお申し込みください。
                            </li>
                          </ul>
                          <ul>
                            <li style={{ listStyleType: "none" }}>
                              ➡{" "}
                              <a
                                href="https://store.piascore.com/contact?opinion_type=pay_by_bank"
                                target="_blank"
                                rel="noopener"
                              >
                                お問合せフォーム
                              </a>
                            </li>
                          </ul>
                          <li>コンビニ決済・スマホ決済には、未対応です</li>
                          <li>
                            プリペイド型のクレジットカードは、カード会社によって未対応の場合がございます
                            <ul>
                              <li>
                                <a
                                  href="https://help.pay.jp/ja/articles/3438182-%E3%83%87%E3%83%93%E3%83%83%E3%83%88%E3%82%AB%E3%83%BC%E3%83%89%E3%82%84%E3%83%97%E3%83%AA%E3%83%9A%E3%82%A4%E3%83%89%E3%82%AB%E3%83%BC%E3%83%89%E3%81%A7%E6%B1%BA%E6%B8%88%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B"
                                  target="_blank"
                                >
                                  {" "}
                                  デビットカードやプリペイドカードで決済できますか？
                                  | PAY.JP ヘルプ
                                </a>
                              </li>
                              <li>
                                詳しくは、弊社決済代行{" "}
                                <a href="https://pay.jp/contact" target="_blank">
                                  PAY.JP
                                </a>{" "}
                                へお問い合わせいただけますようお願いいたします
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={styles.cp_content4}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour041" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour041">
                      {" "}
                      ダウンロードした楽譜ファイルがどこにあるかわかりません
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>PCの場合</li>
                          <ul>
                            <li>
                              一般的に「ダウンロード」のフォルダに保存されています
                            </li>
                          </ul>
                          <br />
                          <li> スマートフォンからの場合</li>
                          <ul>
                            <li>
                              ブラウザで開かれますので、保存いただけますようお願いいたします
                            </li>
                          </ul>
                          <br />
                          <li> 上記がわからない場合</li>
                          <ul>
                            <li>
                              ダウンロードした際に送信しておりますパスワードのメールに、同じ楽譜ファイルを添付しておりますので、そちらをお使いいただけますようお願いします。
                              メールに添付がない場合は
                              <a target="_blank" href="/contact">
                                こちら
                              </a>
                              よりご連絡いただけますようお願いいたします
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour042" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour042">
                      {" "}
                      ダウンロードに失敗しました
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        ダウンロードした際に送信しておりますパスワードのメールに、同じ楽譜ファイルを添付しておりますので、そちらをお使いいただけますようお願いします。{" "}
                        <br />
                        <br />
                        メールに添付がない場合は
                        <a href="/contact" target="_blank">
                          こちら
                        </a>
                        よりご連絡いただけますようお願いいたします
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={styles.cp_content5}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour051" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour051">
                      {" "}
                      購入した楽譜を閲覧するにはどうすればいいですか？
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        PDFを見るためのアプリケーション Adobe Acrobat Reader DC
                        やMacのプレビューでご覧になれます。
                        <br />
                        <br />
                        Adobe Acrobat Reader DC
                        は下記サイトよりダウンロードすることができます
                        <br />
                        <br />
                        <a
                          href="https://get.adobe.com/jp/reader/"
                          target="_blank"
                        >
                          Adobe Acrobat Reader DC ダウンロード | 無料の
                          Windows、Mac OS、Android 向け PDF ビューア{" "}
                        </a>
                        <br />
                        （Adobe Acrobat Reader DCのサイトが開きます）
                        <br />
                        <br />
                        また、iPad、iPhoneでは、電子楽譜ビューアアプリ
                        <a href="https://apps.apple.com/jp/app/piascore-hd/id406141702">
                          Piascore
                        </a>
                        での閲覧も可能です。
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour052" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour052"> 楽譜が開けません</label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        PDFを見るためのアプリケーション Adobe Acrobat Reader DC
                        などは入っていますか
                        <br />
                        <br />
                        Adobe Acrobat Reader DC
                        は下記サイトよりダウンロードすることができます
                        <br />
                        <br />
                        <a
                          href="https://get.adobe.com/jp/reader/"
                          target="_blank"
                        >
                          Adobe Acrobat Reader DC ダウンロード | 無料の
                          Windows、Mac OS、Android 向け PDF ビューア{" "}
                        </a>
                        <br />
                        （Adobe Acrobat Reader DCのサイトが開きます）
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour053" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour053">
                      パスワードを入れると「不正なパスワード」と表示されます
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>閲覧用のパスワードを入れていますか</li>
                          <ul>
                            <li>ログイン用のパスワードとは異なります </li>
                          </ul>
                          <li>パスワードは全て半角で入力していますか</li>
                          <li>
                            パスワードは大文字・小文字を区別しますのでご注意ください
                          </li>
                          <ul>
                            <li>「A（大文字）」「a（小文字）」</li>
                          </ul>
                          <li>
                            文字の最初や最後、文字の間にスペースが入力されていませんか？
                          </li>
                          <ul>
                            <li>
                              コピー＆ペーストをすると、文字の前後にスペースが入る場合があります
                            </li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour054" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour054">
                      楽譜閲覧のパスワードがわからなくなりました
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>方法1：メールで確認する</li>
                          <ul>
                            <li>
                              ダウンロード直後に配信される「[Piascore 楽譜ストア]
                              楽譜の閲覧パスワード」というメールに、パスワードが記載されています。
                            </li>
                          </ul>
                          <li>方法2：楽譜ページから確認する</li>
                          <ul>
                            <li>
                              パスワードは、購入した楽譜ページの右上に表示されます（ログインが必要です）
                            </li>
                          </ul>
                        </ul>
                        <br />
                        詳しくは{" "}
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360000842912-%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89%E5%BE%8C%E3%81%AE%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%81%8C%E3%82%8F%E3%81%8B%E3%82%89%E3%81%AA%E3%81%8F%E3%81%AA%E3%82%8A%E3%81%BE%E3%81%97%E3%81%9F"
                          target="_blank"
                        >
                          ダウンロード後のパスワードがわからなくなりました
                        </a>{" "}
                        をご覧ください
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour055" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour055">
                      紙の楽譜を送ってもらうことはできますか？
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        紙での発送は対応しておりません
                        <br />
                        コンビニでの印刷ができるように手配いたしますので、下記をご覧ください。
                        <br />
                        <br />
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360038388632-%E8%B3%BC%E5%85%A5%E3%81%97%E3%81%9F%E6%A5%BD%E8%AD%9C%E3%82%92%E3%82%B3%E3%83%B3%E3%83%93%E3%83%8B%E3%81%A7%E5%8D%B0%E5%88%B7%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-"
                          target="_brank"
                        >
                          購入した楽譜をコンビニで印刷できますか？
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={styles.cp_content6}>
              <div className={styles.tab_content} />
              <div className={styles.contact_qain}>
                <div className={styles.contact_qa}>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour061" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour061">
                      {" "}
                      購入した楽譜を印刷することはできますか？
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        <ul>
                          <li>iPad、iPhone からの印刷</li>
                          <ul>
                            <li>
                              iPad・iPhoneからの印刷は、AirPrintが利用できます。
                            </li>
                            下記のApple社の公式サイトをご参考ください。
                            <br />
                            <a
                              href="https://support.apple.com/ja-jp/HT201387"
                              target="_brank"
                            >
                              AirPrint を使って iPhone、iPad、iPod touch
                              からプリントする - Apple サポート
                            </a>
                          </ul>
                          <br />
                          <li> パソコンからの印刷</li>
                          <ul>
                            <li>
                              パソコンからダウンロードし、Adobe Acrobat
                              ReaderなどのPDFリーダーで開いて、印刷してください
                            </li>
                          </ul>
                          <br />
                          <li> コンビニで印刷</li>
                          <ul>
                            <li>下記をご覧ください。</li>
                            <a
                              href="https://help.piascore.com/hc/ja/articles/360038388632-%E8%B3%BC%E5%85%A5%E3%81%97%E3%81%9F%E6%A5%BD%E8%AD%9C%E3%82%92%E3%82%B3%E3%83%B3%E3%83%93%E3%83%8B%E3%81%A7%E5%8D%B0%E5%88%B7%E3%81%A7%E3%81%8D%E3%81%BE%E3%81%99%E3%81%8B-"
                              target="_brank"
                            >
                              購入した楽譜をコンビニで印刷できますか？
                            </a>
                          </ul>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour062" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour062">
                      {" "}
                      購入した楽譜をコンビニで印刷できますか？
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        楽譜の仕様上、ご購入された楽譜をお客様ご自身でコンビニプリントに登録することはできません。
                        <br />
                        <br />
                        コンビニでのプリントをご希望の方は、ご購入後、楽譜ページよりお申し込みください。
                      </div>
                      <div className={styles.contact_actab_conbeni}>
                        <img
                          className={styles.contact_conveni_manual}
                          src="/assets/about/conveni_manual-a26309d711fa5721ef88fa8abd15dc5752a3e7918efca45a137a4a9d79ffc83f.png"
                          alt="Conveni manual"
                        />
                      </div>
                      <div className={styles.contact_actab_content_text}>
                        <br />
                        詳しくは、下記をご覧ください.
                        <br />
                        <br />
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360038987412"
                          target="_brank"
                        >
                          コンビニ印刷とはなんですか？
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.contact_actab}>
                    <input id="contact_tabfour063" name="tabs" type="checkbox" />
                    <label htmlFor="contact_tabfour063">
                      音符が✕になったり、矢印が印刷されます
                    </label>
                    <div className={styles.contact_actab_content}>
                      <div className={styles.contact_actab_content_text}>
                        楽譜を印刷すると音符や五線譜が表示されない場合は、下記サイトをご確認ください
                        <br />
                        <br />
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360021068692"
                          target="_brank"
                        >
                          キレイに印刷できません
                        </a>
                        <br />
                        <br />
                        上記をお試しになっても、うまく印刷できない場合は、コンビニ印刷を手配いたします。
                        <br />
                        <br />
                        詳しくは、下記をご覧ください.
                        <br />
                        <br />
                        <a
                          href="https://help.piascore.com/hc/ja/articles/360038987412"
                          target="_brank"
                        >
                          コンビニ印刷とはなんですか？{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.contact_comment_bottom}>
          ご質問が見つからない場合は、
          <a
            href="https://help.piascore.com/hc/ja/categories/360000082731-Piascore-%E6%A5%BD%E8%AD%9C%E3%82%B9%E3%83%88%E3%82%A2"
            target="_brank"
          >
            Piascore 楽譜ストア Q&amp;A
          </a>
          をご覧いただくか、
          <span className="sp_hidden">
            <br />
          </span>
          <a href="/contact">お問い合わせフォーム</a> よりご連絡ください
        </div>
      </div>
    </div>
  );
}
