import React from 'react'
import {ReactElement, useEffect } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import './PSStoreHowtoComponent.scss'; // ここにCSSファイルをインポート


const PSStoreHowtoComponent = () => {

  useEffect(() => {
    if (process.browser) {
      gsap.registerPlugin(ScrollTrigger)
      setAnimation()
    }
  }, [])
  
  const setAnimation = () => {
    const elements = document.querySelectorAll('.scrollUp');
  
    elements.forEach((element) => {
      gsap.fromTo(
        element,
        { opacity: 0, y: 150 }, //fromの設定
        {  //toの設定
          opacity: 1,
          y: 0,
          duration: 1,
          scrollTrigger: {
            trigger: element,
            start: 'top bottom', //要素のトップが、画面の中央まできたら開始
            end: 'bottom center', //要素のボトムが、画面の中央まできたら終了
          },
        }
      )
    });
  }
  
  const howto_message = [
    "ユーザ登録を行い、ログインしてください",
    "欲しい楽譜を探してください",
    "欲しい楽譜をクリックしてください",
    "「購入する」をクリックしてください",
    "クレジットカード情報を入力してください",
    "購入が完了したら、「ダウンロード」をクリックしてください",
    "メールでパスワードが届きます",
    "ダウンロードしたPDFのパスワードを解除してください"
  ]
  
  return(
    
    <>
      <div className="howtobuy_wrapper">
        <div className="bold-title">楽譜の購入方法</div>
        <div className="howtobuy_explanation" style={{ color: "#828080" }}>
          Piascore 楽譜ストアでの楽譜の購入方法をご説明します。
        </div>
        
        { howto_message.map((message, index) => (
          <div>
            <div style={{textAlign: "center", margin: "0 0 75px 0", position: "relative" }}>
              { index === 0 ? 
                (
                  <>
                    <div className="howtobuy_explan_text">
                      {`${index + 1}. ${message}`}
                    </div>
                    <div style={{ margin: "auto 0" }}>
                      <img className="howtobuy_image" src={`/images/howto/howto_${index+1}.png`} />
                    </div>
                  </>
                ) 
                : 
                (
                  <>
                    <div className="howtobuy_explan_text scrollUp">
                      {`${index + 1}. ${message}`}
                    </div>
                    <div className="scrollUp" style={{ margin: "auto 0" }}>
                      <img className="howtobuy_image" src={`/images/howto/howto_${index+1}.png`} />
                    </div>
                  </>
                )
              }
            </div>
          </div>
        ))}
        
        <div>
          <div className="howtouse_container" style={{backgroundColor: "#0083EA", borderRadius: 100, height: 57, display: "flex", alignItems: "center", justifyContent: "center", margin: "0 auto" }}>
            <div className="howtouse_title" style={{ color: "#FFFFFF", fontWeight: "bold" }}>
              楽譜を見たり、印刷できます
            </div>
          </div>
          <div className="howtouse_wrapper" style={{ overflow: "hidden" }}>
            <div style={{ width: "40%", float: "left" }}>
              <div
                className="howtouse_img_container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  className="howtopc_image"
                  src="/images/howto/on_pc.png"
                  alt="On pc"
                />
              </div>
              <div
                className="howto_text"
                style={{ textAlign: "center", fontWeight: "bold", color: "#0083EA" }}
              >
                PCで
              </div>
            </div>
            <div style={{ width: "30%", float: "left" }}>
              <div
                className="howtouse_img_container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  className="howtotablet_image"
                  src="/images/howto/on_tablet.png"
                  alt="On tablet"
                />
              </div>
              <div
                className="howto_text"
                style={{ textAlign: "center", fontWeight: "bold", color: "#0083EA" }}
              >
                タブレットで
              </div>
            </div>
            <div style={{ width: "30%", float: "left" }}>
              <div
                className="howtouse_img_container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  className="howtopaper_image"
                  src="/images/howto/on_paper.png"
                  alt="On paper"
                />
              </div>
              <div
                className="howto_text"
                style={{ textAlign: "center", fontWeight: "bold", color: "#0083EA" }}
              >
                紙で
              </div>
            </div>
          </div>
        </div>
        
        <div className="convini_border" style={{ borderBottom: "1px solid #0083EA" }} />
        <div
          className="conveni_wrapper"
          id="div"
          style={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #0083EA",
            borderRadius: 100,
            height: 57,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto"
          }}
        >
          <div
            className="convini_title"
            id="div"
            style={{ fontSize: 28, color: "#0083EA", fontWeight: "bold" }}
          >
            ご注意
          </div>
        </div>
        
        <div className="conveni_container" style={{ width: "100%" }}>
          <div className="conveni_text">
            ダウンロードしたファイルは、仕様上、コンビニのプリンターでは印刷できません。
            <br />
            <br />
            コンビニでのプリントをご希望の方は、ご購入後、楽譜ページよりお申し込みください。
            <br />
          </div>
          <div
            className="conveni_manual_img fromBottomIn"
            data-scroll="toggle(fromBottomIn,fromBottomOut)"
            style={{ margin: "30px auto" }}
          >
            <img
              className="conveni_manual"
              src="/assets/about/conveni_manual-a26309d711fa5721ef88fa8abd15dc5752a3e7918efca45a137a4a9d79ffc83f.png"
              alt="Conveni manual"
            />
          </div>
        </div>
        
        <div style={{ textAlign: "center", margin: "40px 0 0 0" }}>
          <a href="/">
            <i className="fa fa-home" />
            ホームに戻る
          </a>
        </div>
      
      </div>

    </>
  )
};

export default PSStoreHowtoComponent;