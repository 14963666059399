import React from 'react';
import './PSStoreInstrumentListComponent.scss'; // ここにCSSファイルをインポート

export default function PSStoreInstrumentListComponent() {
  const json = [{
    category: {
      num: 57666,
      regions: {
        ja: {
          name: "ピアノ"
        }
      }
    },
    instruments: [{
        instrument_id: 10,
        num: 44031,
        regions: {
          ja: {
            name: "ピアノ（ソロ）"
          }
        }
      },
      {
        instrument_id: 20,
        num: 8312,
        regions: {
          ja: {
            name: "ピアノ（弾き語り）"
          }
        }
      },
    ]
  },
  {
    category: {
      num: 64824,
      regions: {
        ja: {
          name: "ギター"
        }
      }
    },
    instruments: [{
        instrument_id: 100,
        num: 6896,
        regions: {
          ja: {
            name: "ギター（弾き語り）"
          }
        }
      },
      {
        instrument_id: 110,
        num: 7838,
        regions: {
          ja: {
            name: "ギター（ソロ）"
          }
        }
      },
    ]
  }
]

  return (
    <div className="container">
      <div className="list-page">
        <div className="head">
          <h1>全ての楽器</h1>
        </div>
        <div className="body">
          {json.map((category) => (
            <ul className="list-group list-group-flush item-group">
            <React.Fragment key={category.category.num}>
              <li className="list-group-item d-flex justify-content-between title">
                {category.category.regions.ja.name}
                <span className="badge bg-secondary rounded-pill">
                  {category.category.num.toLocaleString()}
                </span>
              </li>
              {category.instruments.map((instrument) => (
                <a
                  key={instrument.instrument_id}
                  className="list-group-item d-flex justify-content-between align-items-start item"
                  href={`/search?i=${instrument.instrument_id}`}
                >
                  {instrument.regions.ja.name}
                  <span className="badge bg-secondary rounded-pill">
                    {instrument.num.toLocaleString()}
                  </span>
                </a>
              ))}
            </React.Fragment>
          </ul>
          ))}
        </div>
      </div>
    </div>
  );
}
